<template lang="html">

  <section class="login">
    
    <!-- <div class="container" style="width: 400px;margin-top: 50px;">
        <div class="row">
            <div class="col">
                <div class="card border rounded-0 shadow">
                    <div class="card-body shadow-sm">
                        <h4 class="card-title">Log in</h4>
                        <p class="card-text" style="margin-top: 20px;color: rgb(120,121,122);">If you have an account, please log in with one of the following....</p>
                        <form style="margin-top: 20px;" action="https://localhost:3000/login">
                            <div class="form-row">
                                <div class="col d-flex justify-content-center justify-content-sm-center justify-content-xl-center"><img src="img/facebook-logo.png" height="32px" style="width: 48px;height: 48px"><img src="img/google-logo.png" height="32px" style="width: 48px;height: 48px;;margin-left: 10px;margin-right: 10px;"><img src="img/linkedin-logo.png"
                                        height="32px" style="width: 48px;height: 48px;"></div>
                            </div>
                            <p class="d-flex d-sm-flex d-xl-flex justify-content-center justify-content-sm-center justify-content-xl-center" style="font-size: 10pt;margin-top: 11px;color: rgb(129,129,129);">or</p><input class="form-control form-control-sm" type="text" name="username" placeholder="username" style="margin-top: 15px;"><input class="form-control form-control-sm" type="password" placeholder="password" name="password"
                                style="margin-top: 10px;">
                            <div class="form-row">
                                <div class="col d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-center"><button class="btn btn-primary btn-sm" type="button" style="margin-top: 20px;margin-right: 10px;" @click="signIn">&nbsp; &nbsp;Sign in&nbsp; &nbsp;</button></div>
                            </div>
                            <div class="form-row" style="margin-top: 15px;font-size: 10pt;">
                                <div class="col d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-xl-center"><a href="#/create-account" style="margin-right: 7px;">Create account</a><span style="margin-right: 7px;">or</span><a href="#/reset-password">reset password</a></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="wrapper">
      <span class="icon-close"><ion-icon name="close"></ion-icon></span>
      <div class="form-box login">
        <h2>Login</h2>
        <form action="#">
          <div class="input-box">
            <input type="email" required>
            <span class="icon"><ion-icon name="person"></ion-icon></span>
            <label>Username</label>
          </div>
          <div class="input-box">
            <input type="password" required>
            <span class="icon"><ion-icon name="lock-closed"></ion-icon></span>
            <label>Password</label>
          </div>
          <div class="remember-forget">
            <label><input name="rememberme" type="checkbox">Remember me</label>
            <a href="#">Forgot Password?</a>
          </div>
          <button type="submit" class="btn">Login</button>
          <div class="login-register">
            <p>Don't have an account? &nbsp;<a href="#" class="register-link">Register</a></p>
          </div>
        </form>
      </div>
      <div class="form-box register">
        <h2>Registration</h2>
        <form action="#">
          <div class="input-box">
            <input name="username" type="text" required>
            <span class="icon"><ion-icon name="person"></ion-icon></span>
            <label>Username</label>
          </div>
          <div class="input-box">
            <input name="email" type="email" required>
            <span class="icon"><ion-icon name="mail"></ion-icon></span>
            <label>Email</label>
          </div>
          <div class="input-box">
            <input name="password" type="password" required>
            <span class="icon"><ion-icon name="lock-closed"></ion-icon></span>
            <label>Password</label>
          </div>
          <div class="input-box">
            <input name="password2" type="password" required>
            <span class="icon"><ion-icon name="lock-closed"></ion-icon></span>
            <label>Confirm Password</label>
          </div>
          <div class="remember-forget">
            <label><input name="agree" type="checkbox">I agree to the terms and conditions</label>
          </div>
          <button type="submit" class="btn">Register</button>
          <div class="login-register">
            <p>Already have an account? &nbsp;<a href="#" class="login-link">Login</a></p>
          </div>
        </form>
      </div>
    </div>
    
  </section>

</template>

<script lang="js">

  export default  {
    name: 'Login',
    props: [],
    mounted () 
      {
        const wrapper      = document.querySelector('.wrapper');
        const loginLink    = document.querySelector('.login-link');
        const registerLink = document.querySelector('.register-link');
        const btnPopup     = document.querySelector('.btnLogin-popup');
        const iconClose    = document.querySelector('.icon-close');

        const form   = document.querySelector("form"),
          emailField = form.querySelector(".email-field"),
          emailInput = emailField.querySelector(".email"),
          passField  = form.querySelector(".create-password"),
          passInput  = passField.querySelector(".password"),
          cPassField = form.querySelector(".confirm-password"),
          cPassInput = cPassField.querySelector(".cPassword");

        registerLink.addEventListener('click', () => {
          wrapper.classList.add('active');
        });

        loginLink.addEventListener('click', () => {
          wrapper.classList.remove('active');
        });

        btnPopup.addEventListener('click', () => {
          wrapper.classList.add('active-popup');
        });

        iconClose.addEventListener('click', () => {
          wrapper.classList.remove('active-popup');
        });
      },
    data () 
      {
        return {

        }
      },
    methods: 
      {
        signIn: function() { return; }
        // signIn: function(event)
        //   {
        //     axios.get( 
        //       {
        //         method: 'post',
        //         url: '/user/12345',
        //         data: {
        //           firstName: 'Fred',
        //           lastName: 'Flintstone'
        //         }
        //       })
        //     .then(function (response) 
        //       {
        //         console.log(response);
        //       })
        //     .catch(function (error) 
        //       {
        //         console.log(error);
        //       });
      },
    computed:
      {
      }
}


</script>

<style scoped lang="scss">
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;300&display=swap');

* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  background: url('../../public/confirm-email/assets/img/prairie-storm.png') no-repeat;
  background-size: cover;
  background-position: center;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
}

.logo {
  font-size: 2em;
  color: #fff;
  user-select: none;
}

.navigation a {
  position: relative;
  font-size: 1.1em;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  margin-left: 40px;
  transition: .5s;
}

.navigation a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -6px;
  width: 100%;
  height: 3px;
  background: #fff;
  border-radius: 5px;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .5s;
}

.navigation a:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}

.navigation .btnLogin-popup {
  width: 130px;
  height: 50px;
  background: transparent;
  border: 2px solid #fff;
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.1em;
  color: #fff;
  font-weight: 500;
  margin-left: 40px;
}

.navigation .btnLogin-popup:hover {
  background: #fff;
  color: #162938;
}

.wrapper {
  position: relative;
  width: 400px;
  height: 440px;
  background: transparent;
  border: 2px solid rgba(255,255,255,.5);
  border-radius: 20px;
  backdrop-filter: blur(20px);
  box-shadow: 0 0 30px rgba(0,0,0,.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // transform: scale(0);
  transition: transform .5s ease, height .2s ease;
}

.wrapper.active-popup {
  transform: scale(1);
}

.wrapper.active {
  height: 600px;
}

.wrapper .form-box {
  width: 100%;
  padding: 40px;
}

.wrapper .form-box.login {
  transition: transform .18s ease;
  transform: translateX(0);
}

.wrapper.active .form-box.login {
  transition: none;
  transform: translateX(-400px);
}

.wrapper .form-box.register {
  position: absolute;
  transition: none;
  transform: translateX(400px);
}

.wrapper.active .form-box.register {
  transition: transform .18s ease;
  transform: translateX(0);
}

.wrapper .icon-close {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 45px;
  height: 45px;
  background: #162938;
  font-size: 1em;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items:center;
  border-bottom-left-radius: 20px;
  cursor: pointer;
  z-index: 1
}
.form-box h2 {
  font-size: 2em;
  color: #162938;
  text-align: center;
}

.input-box {
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #162938;
  margin: 30px 0;
}

.input-box label {
  position: absolute;
  top: 50%;
  left: 35px;
  transform: translateY(-50%);
  font-size: 1em;
  color: #162938;
  font-weight: 500;
  pointer-events: none;
  transition: .5s;
}

.input-box input:focus~label,
.input-box input:valid~label {
  top: -2px;
}

.input-box input:focus~span,
.input-box input:valid~span {
  top: -1px;
}

.input-box input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1em;
  color: #162938;
  font-weight: 600;
  padding: 0 35px 0 5px;
}

.input-box .icon {
  position: absolute;
  left: 8px;
  font-size: 1.2em;
  color: #162938;
  line-height: 57px;
  top: 50%;
  transform: translateY(-50%);
  transition: .5s;
}

.remember-forget {
  font-size: .9em;
  color: #162938;
  font-weight: 500;
  margin: -15px 0 15px;
  display: flex;
  justify-content: space-between;
}

.remember-forget label input {
  accent-color: #162938;
  margin-right: 8px;
}

.remember-forget a {
  color: #162938;
  text-decoration: none;
}

.remember-forget a:hover {
  text-decoration: underline;
}

.btn {
  width: 100%;
  height: 35px;
  background: #28445a;
  border: none;
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
  color: #fff;
  font-weight: 200;
}

.login-register {
  font-size: .9em;
  color: #162938;
  text-align: center;
  font-weight: 500;
  margin: 25px 0 10px;
}

.login-register p a {
  color: #162938;
  text-decoration: none;
  font-weight: 600;
}

.login-register p a:hover {
  text-decoration: underline;
}
</style>
