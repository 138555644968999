<template lang="html">

  <section class="credit-card">
   
   <div class="container">

    <div class="article-list">
        <div class="container" id="main-container"><img class="img-fluid shadow" id="lookout-header" src="/img/credit_cards.jpg">
            <div class="row justify-content-center articles" style="margin-top: 15px;font-family: 'Alegreya Sans SC', sans-serif;">
                <div class="col">
                    <ol class="breadcrumb" style="background-color: rgb(255,255,255);font-family: Actor, sans-serif;font-size: 12pt;color: rgb(55,59,62);">
                        <li class="breadcrumb-item"><a href="#/"><span style="color: rgb(118,119,119);">Home</span></a></li>
                        <li class="breadcrumb-item"><a href="#"><span style="color: rgb(118,119,119);">Credit Card Calculator</span></a></li>
                    </ol>
                </div>
            </div>
            <div class="row" style="margin-top: -40px;">
                <div class="col">
                    <div class="btn-toolbar bg-light d-flex justify-content-between">
                        <div class="btn-group" role="group">
                          <button @click="deleteSelected()" class="btn btn-light" type="button" style=";margin-left: 23px;">
                          <i class="fas fa-trash-alt" data-toggle="tooltip" data-bs-tooltip="" title="Delete"></i></button>
                        </div>
                        <div class="btn-group" role="group">
                          <button @click="addCard()" class="btn btn-light" type="button" style="padding-right: 12px;padding-left: 12px;">
                            <i class="fa fa-plus" data-toggle="tooltip" data-bs-tooltip="" title="Add"></i>
                          </button>
                          <button @click="saveCards()" class="btn btn-light" type="button" style="padding-right: 12px;padding-left: 12px;">
                            <i class="fa fa-save" data-toggle="tooltip" data-bs-tooltip="" title="Save"></i>
                          </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-cards"  style="margin-bottom: -10px;">
                    <thead>
                        <tr>
                            <th class="text-left" style="width: 9%;">
                                <div class="form-check">
                                  <input @click="toggleSelectAll($event)" class="form-check-input" type="checkbox" id="formCheck-1" style="width: 14px;height: 14px;margin-top:0px; margin-left: 3px;">
                                  <label class="form-check-label" for="formCheck-1"></label>
                                </div>
                            </th>
                            <th class="text-left" style="width: 25%;">Name</th>
                            <th class="text-left" style="width: 15%;">Balance</th>
                            <th class="text-left" style="width: 10%;">Rate</th>
                            <th class="text-left" style="width: 13%;">Monthly Charges</th>
                            <th class="text-left" style="width: 15%;">Minimum Payment</th>
                            <th class="text-left" style="width: 13%;">Actual Payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(card, index) of cards" :key="card.form.id">

                            <!-- <td class="flex-nowrap">
                              <div class="d-flex align-items-center flex-nowrap icon-wrapper">
                                <input v-model="card.form.selected" type="checkbox" style="margin-right: 10px;width: 14px;height: 14px;">
                                <button @click="copyCard(index)" class="btn d-xl-flex align-items-xl-start" type="button" style="padding-left: 6px;padding-right: 6px;">
                                  <i class="fa fa-copy"></i>
                                </button>
                                <button @click="deleteCard(index)" class="btn d-xl-flex justify-content-xl-center" type="button" style="padding-left: 6px;padding-right: 6px;">
                                  <i class="fa fa-trash"></i>
                                </button>
                              </div>
                            </td> -->

                            <td class="flex-nowrap">
                              <div class="d-flex align-items-center flex-nowrap icon-wrapper">
                                <i v-if="card.show" @click="hideCard(index)" class="fa fa-eye card-icon"></i>
                                <i v-else @click="showCard(index)" class="fa fa-eye-slash card-icon"></i>
                                <input v-model="card.form.selected" type="checkbox" style="margin-right: 10px;width: 14px;height: 14px;">
                                <i @click="copyCard(index)" class="fa fa-copy card-icon"></i>
                                <i @click="deleteCard(index)" class="fa fa-trash card-icon"></i>
                              </div>
                            </td>

                            <td>
                                <div class="input-group">
                                    <div class="input-group-prepend"></div><input v-validate="'alpha_dash'" name="name" v-model="card.form.name" class="form-control form-control-sm" type="text">
                                    <div class="input-group-append"></div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="balance" v-model="card.form.balance" class="form-control" type="text">
                                    <div class="input-group-append"></div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group input-group-sm d-flex flex-nowrap">
                                    <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="rate" v-model="card.form.rate" class="form-control" type="text">
                                    <div class="input-group-append"><span class="input-group-text">%</span></div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="charges" v-model="card.form.charges" class="form-control" type="text">
                                    <div class="input-group-append"></div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="minPayment" v-model="card.form.minPayment" class="form-control" type="text">
                                    <div class="input-group-append"></div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="actualPayment" v-model="card.form.actualPayment" class="form-control" type="text">
                                    <div class="input-group-append"></div>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="row" style="margin-top: 0px;">
                <div v-show="cards.length > 0" class="col text-right"><button @click="calc()" class="btn btn-light btn-sm" type="button">Update</button></div>
            </div>            
            <div class="row" style="margin-bottom: 20px; margin-top: 10px;">
                <div class="col">
                    <hr>
                </div>
            </div>
            <div v-show="showTabs" class="row justify-content-center articles" style="font-family: 'Alegreya Sans SC', sans-serif;margin-top: 20px;">
                <div class="col">
                    <div>
                        <ul class="nav nav-tabs">
                            <li class="nav-item"><a class="nav-link active" role="tab" data-toggle="tab" href="#tab-1">Actual Payment Details</a></li>
                             <li class="nav-item"><a class="nav-link" role="tab" data-toggle="tab" href="#tab-2">Minimum Payment Details</a></li>
                            <li class="nav-item"><a class="nav-link" role="tab" data-toggle="tab" href="#tab-3">Credit Card Summary</a></li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active" role="tabpanel" id="tab-1">
                                <div class="row" style="margin-top: 10px;">
                                    <div class="col">

                                      <div class="col" style="max-width: 100%; overflow:auto; margin-top: 10px;">
                                        <!-- <h6>Payment Schedule</h6><br> -->
                                        <div class="chart-wrapper">
                                          <canvas id="actualPaymentsChart" max-width="100" max-height="100"></canvas>
                                        </div>
                                        <p class="text-left" style="font-size: 8pt; font-style: italic; margin-left: 25px; margin-top: 10px">* End-of-Year Values</p>
                                      </div>                                   

                                    </div>
                                </div>

                                <div class="row" style="margin-top: 20px;">
                                    <div class="col">
                                        <div v-if="chartData && chartData['act']" class="table-responsive table-bordered" style="margin-top: 15px;">
                                            <table class="table table-bordered table-sm">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 15%;">Date</th>
                                                        <th style="width: 17%;">Payment</th>
                                                        <th style="width: 17%;">Principal Paid</th>
                                                        <th style="width: 17%;">Interest Paid</th>
                                                        <th style="width: 17%;">Total Interest</th>
                                                        <th style="width: 17%;">Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="border rounded-0">
                                                    <tr v-for="(date, index) in chartData['act'].date" :key="date.toString()">
                                                        <td>{{dateFormat(date)}}</td>
                                                        <td>{{currencyFormat(chartData['act'].payment[index])}}</td>
                                                        <td>{{currencyFormat(chartData['act'].principalPaid[index])}}</td>
                                                        <td>{{currencyFormat(chartData['act'].interestPaid[index])}}</td>
                                                        <td>{{currencyFormat(chartData['act'].totalInterest[index])}}</td>
                                                        <td>{{currencyFormat(chartData['act'].balance[index])}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" role="tabpanel" id="tab-2">
                                <div class="row" style="margin-top: 10px;">
                                    <div class="col">

                                      <div class="col" style="max-width: 100%; overflow:auto; margin-top: 10px;">
                                        <!-- <h6>Payment Schedule</h6><br> -->
                                        <div class="chart-wrapper">
                                          <canvas id="minimumPaymentsChart" max-width="100" max-height="100"></canvas>
                                        </div>
                                        <p class="text-left" style="font-size: 8pt; font-style: italic; margin-left: 25px; margin-top: 10px">* End-of-Year Values</p>
                                      </div>                                   

                                    </div>
                                </div>

                                <div class="row" style="margin-top: 20px;">
                                    <div class="col">
                                        <div v-if="chartData && chartData['min']" class="table-responsive table-bordered" style="margin-top: 15px;">
                                            <table class="table table-bordered table-sm">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 15%;">Date</th>
                                                        <th style="width: 17%;">Payment</th>
                                                        <th style="width: 17%;">Principal Paid</th>
                                                        <th style="width: 17%;">Interest Paid</th>
                                                        <th style="width: 17%;">Total Interest</th>
                                                        <th style="width: 17%;">Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="border rounded-0">
                                                    <tr v-for="(date, index) in chartData['min'].date" :key="date.toString()">
                                                        <td>{{dateFormat(date)}}</td>
                                                        <td>{{currencyFormat(chartData['min'].payment[index])}}</td>
                                                        <td>{{currencyFormat(chartData['min'].principalPaid[index])}}</td>
                                                        <td>{{currencyFormat(chartData['min'].interestPaid[index])}}</td>
                                                        <td>{{currencyFormat(chartData['min'].totalInterest[index])}}</td>
                                                        <td>{{currencyFormat(chartData['min'].balance[index])}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" role="tabpanel" id="tab-3" style="font-family: Arial;font-size: 10pt;">

                                <div class="row" style="margin-top: 20px;">
                                  <div class="col-5">
                                    <h6>Loan Details</h6><br>

                                    <div class="row" style="flex-direction: row">

                                      <div class="col" style="min-width: 100px">
                                        <p class="text-right">Initial Balance:</p>
                                        <p class="text-right">Payoff:</p>
                                        <p class="text-right">Monthly Payment:</p>
                                        <p class="text-right">Total Interest Paid:</p>
                                      </div>
                                      <div class="col" style="margin-left: -25px; min-width: 100px">
                                        <p class="text-left">{{currencyFormat(initialBalance)}}</p>
                                        <p class="text-left">{{term + " months"}}</p>
                                        <p class="text-left">{{currencyFormat(payment)}}</p>
                                        <p class="text-left">{{currencyFormat(totalInterest)}}</p>
                                      </div>   

                                    </div>

                                  </div> 

                                  <div class="col-7 text-center" style="max-width: 350px; overflow:auto">
                                    <h6>Interest Paid vs. Principal Paid</h6><br>
                                    <div class="chart-wrapper">
                                      <canvas id="totalInterestChart" max-width="100" max-height="100"></canvas>
                                    </div>
                                  </div>

                                </div>
                                <div class="row" style="margin-top: 25px;">
                                  <div class="col">
                                    <div class="table-responsive table-bordered" style="margin-top: 15px;">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr>
                                                    <th style="width: 15%;">Date</th>
                                                    <th style="width: 17%;">Payments</th>
                                                    <th style="width: 17%;">Principal Paid</th>
                                                    <th style="width: 17%;">Interest Paid</th>
                                                    <th style="width: 17%;">Total Interest</th>
                                                    <th style="width: 17%;">Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody class="border rounded-0">
                                                <tr v-for="item in years" v-bind:key="item.balance">
                                                    <td>{{item.date.toString()}}</td>
                                                    <td>{{currencyFormat(item.payment)}}</td>
                                                    <td>{{currencyFormat(item.principalPaid)}}</td>
                                                    <td>{{currencyFormat(item.interestPaid)}}</td>
                                                    <td>{{currencyFormat(item.totalInterest)}}</td>
                                                    <td>{{currencyFormat(item.balance)}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                  </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  </div>

  </section>

</template>

<script lang="js">
import {DateTime} from 'luxon';
import 'vue-date-pick/dist/vueDatePick.css';
import Chart from 'chart.js';
//import $ from 'jquery';

export default  {
    name: 'credit-card',
    props: [],
    created ()
      { this.addCard(); },
    computed:
      {
        showTabs: function()
          { return((this.calculated && this.chartData['act'] && this.chartData['act']['date'] && this.chartData['act']['date'].length > 0)?true:false); }
      },
    mounted () 
      {
        // eslint-disable-next-line no-undef
        //$('#datepicker').datepicker({ uiLibrary: 'bootstrap4', value: this.startDate });

        //this.calc();

        // eslint-disable-next-line no-undef
        //$('#datepicker').datepicker({ uiLibrary: 'bootstrap4' });
      },
    data () 
      {
        return(
          {
            actualPaymentsChart: null,
            calculated: false,
            cards: [],
            chartData: null,
            initialBalance: 0,
            minimumPaymentsChart: null,
            numFormat: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'symbol' }),
            payment: 0,
            startDate: DateTime.now().toFormat('LL/dd/yyyy'),
            term: 0,
            totalInterest: 0,
            totalInterestChart: null,
            years: null
          });
      },
    methods: {
      addCard: function()
        {
          this.cards.push(
            {
              form:
                {
                  selected:      false,
                  id:            this.getUUID(),
                  name:          '',
                  balance:       '',
                  rate:          '',
                  charges:       '',
                  minPayment:    '',
                  actualPayment: ''
                },
              show: true
            });
        },
      aggregateSeries(dst, src)
        {
          for (let i in src.date)
            {
              if (typeof dst.date[i] === 'undefined')
                { 
                  dst.balance.push(src.balance[i]);
                  dst.date.push(src.date[i]);
                  dst.payment.push(src.payment[i]);
                  dst.principalPaid.push(src.principalPaid[i]);
                  dst.interestPaid.push(src.interestPaid[i]);
                  dst.totalInterest.push(src.totalInterest[i]);
                }
              else
                {
                  dst.balance[i]       = dst.balance[i] + src.balance[i];
                  dst.payment[i]       = dst.payment[i] + src.payment[i];
                  dst.principalPaid[i] = dst.principalPaid[i] + src.principalPaid[i];
                  dst.interestPaid[i]  = dst.interestPaid[i] + src.interestPaid[i];
                  dst.totalInterest[i] = dst.totalInterest[i] + src.totalInterest[i];                
                }
            }
        },
      calc: function()
        {
          let act                 = {balance: [], date: [], payment: [], principalPaid: [], interestPaid: [], totalInterest: []};
          let min                 = {balance: [], date: [], payment: [], principalPaid: [], interestPaid: [], totalInterest: []};
          let totalActualPeriods  = 0;
          let totalMinimumPeriods = 0;
          let years               = [];

          this.initialBalance     = 0;
          
          for (let card of this.cards)
            {
              let data = {};

              if (card.show)
                {
                  data['act'] = this.calcCardSeries(parseFloat(card.form.balance), this.startDate, parseFloat(card.form.rate)/(12*100), parseFloat(card.form.actualPayment));
                  data['min'] = this.calcCardSeries(parseFloat(card.form.balance), this.startDate, parseFloat(card.form.rate)/(12*100), parseFloat(card.form.minPayment));

                  card['data'] = data;

                  totalActualPeriods  = Math.max(totalActualPeriods, data['act'].date.length);
                  totalMinimumPeriods = Math.max(totalMinimumPeriods, data['min'].date.length);

                  this.initialBalance += Math.round(parseFloat(card.form.balance)*100/100);
                }
            }

          for (let card of this.cards)
            {
              if (card.show && card.data['act'].date.length>0)
                {
                  for (let i=card.data['act'].date.length; i<totalActualPeriods; i++)
                    {
                      card.data['act'].balance.push(0);
                      card.data['act'].date.push(DateTime.fromISO(card.data['act'].date[i-1]).plus({months: 1}));
                      card.data['act'].payment.push(0);
                      card.data['act'].principalPaid.push(0);
                      card.data['act'].interestPaid.push(0);
                      card.data['act'].totalInterest.push(card.data['act'].totalInterest[i-1]);
                    }
                }
              if (card.show && card.data['min'].date.length>0)
                {
                  for (let i=card.data['min'].date.length; i<totalMinimumPeriods; i++)
                    {
                      card.data['min'].balance.push(0);
                      card.data['min'].date.push(DateTime.fromISO(card.data['min'].date[i-1]).plus({months: 1}));
                      card.data['min'].payment.push(0);
                      card.data['min'].principalPaid.push(0);
                      card.data['min'].interestPaid.push(0);
                      card.data['min'].totalInterest.push(card.data['min'].totalInterest[i-1]);
                    }
                }
            }

          for (let card of this.cards)
            {
              if (card.show)
                {
                  this.aggregateSeries(min, card.data['min']);
                  this.aggregateSeries(act, card.data['act']);
                }
            } 

          this.payment        = (act['payment'] && act['payment'].length > 0)?act.payment[0]:0;
          this.term           = act.date.length;
          this.totalInterest  = (act['totalInterest'] && act['totalInterest'].length > 0)?act.totalInterest[act.totalInterest.length-1]:0; 

          this.chartData = {act: act, min: min};  

          let currentYear = act.date[0].year;
          let yearDetails = 
            { 
              date: currentYear,
              payment: 0,
              interestPaid: 0,
              principalPaid: 0,
              totalInterest: 0,
              balance: 0
            };

          for (let i=0; i<act.date.length; i++)    
            {
              if (act.date[i].year == currentYear)
                {
                  yearDetails.payment       += act.payment[i];
                  yearDetails.interestPaid  += act.interestPaid[i];
                  yearDetails.principalPaid += act.principalPaid[i];
                  yearDetails.totalInterest  = act.totalInterest[i];
                  yearDetails.balance        = act.balance[i];
                }  
              else              
                {
                  if (i < act.date.length-1)
                    { years.push(yearDetails); }
                  currentYear++;

                  yearDetails = 
                    { 
                      date:          currentYear,
                      payment:       act.payment[i],
                      interestPaid:  act.interestPaid[i],
                      principalPaid: act.principalPaid[i],
                      totalInterest: act.totalInterest[i],
                      balance:       act.balance[i]
                    };
                }
            }  
          years.push(yearDetails);

          this.years = years;  

          if (this.actualPaymentsChart)
            { this.actualPaymentsChart.destroy(); }
          this.createActualPaymentsChart();
          if (this.minimumPaymentsChart)
            { this.minimumPaymentsChart.destroy(); }
          this.createMinimumPaymentsChart();
          if (this.totalInterestChart)
            { this.totalInterestChart.destroy(); }
          this.createTotalInterestChart(); 

          this.calculated = true;
        },
      calcCardSeries(bal, start, rate, payment)
        {
          let data =                     
            {
              balance: [],
              date: [],
              payment: [],
              principalPaid: [],
              interestPaid: [],
              show: true,
              totalInterest: []
            };
          let date               = (new DateTime(start)).plus({months: 1});
          let totalInterest      = 0;

          while (bal > 0)
            {
              let interestPaid   = bal * rate;
              let principalPaid  = (bal+interestPaid>payment)?(payment-interestPaid):bal;
              
              totalInterest     += interestPaid;
              bal               -= principalPaid;

              data.date.push(date);
              data.balance.push(Math.round(bal*100)/100);
              data.payment.push(Math.round(payment*100)/100);
              data.principalPaid.push(Math.round(principalPaid*100)/100);
              data.interestPaid.push(Math.round(interestPaid*100)/100);
              data.totalInterest.push(Math.round(totalInterest*100)/100);

              date = date.plus({months: 1});
            }
          
          return(data);
        },
      copyCard: function(index) 
        { 
          this.cards.push(
            {
              form:
                {
                  delete:        false,
                  id:            this.getUUID(),
                  name:          this.cards[index].form.name,
                  balance:       this.cards[index].form.balance,
                  rate:          this.cards[index].form.rate,
                  charges:       this.cards[index].form.charges,
                  minPayment:    this.cards[index].form.minPayment,
                  actualPayment: this.cards[index].form.actualPayment,
                },
              show: true
            });        
        },
      createActualPaymentsChart()
        {
          let data = {labels:[], datasets:[]};

          for (let i=0; i<this.chartData['act'].date.length; i++)
            { data.labels.push(this.chartData['act'].date[i].toFormat('LL/yy')); }

          data.datasets.push(
            {
              label: 'Balance',
              data: this.chartData['act'].balance,
              type: 'line',
              fill: false,
              borderColor: '#aaaaaa',
              borderWidth: 2,
              yAxisID: 'y-bal'
            });
          data.datasets.push(
            {
              label: 'Interest Paid',
              backgroundColor: '#483D8B',
              data: this.chartData['act'].interestPaid,
              yAxisID: 'y-payments'
            });
          data.datasets.push(
            {
              label: 'Principal Paid',
              backgroundColor: '#87cefa',
              data: this.chartData['act'].principalPaid,
              yAxisID: 'y-payments'
            });

           // eslint-disable-next-line no-unused-vars
          this.actualPaymentsChart = new Chart('actualPaymentsChart', 
            {
              type: 'bar',
              responsive: true,
              maintainAspectRatio: false,
              data: data,
              options: 
                {
                  scales: 
                    {
                      xAxes: 
                        [
                          {
                            stacked: true
                          }],
                      yAxes: 
                        [
                          {
                            id: 'y-bal',
                            label: 'Balance',
                            stacked: true,
                            display: true,
                            backgroundColor: '#888888',
                            type: 'linear',
                            position: 'left',
                            scaleLabel: 
                              {
                                display: true,
                                labelString: 'Balance'
                              }
                          },
                          {
                            id: 'y-payments',
                            label: 'Interest and Principal Paid',
                            stacked: true,
                            display: true,
                            backgroundColor: '#888888',
                            type: 'linear',
                            position: 'right',
                            scaleLabel: 
                              {
                                display: true,
                                labelString: 'Interest and Principal Paid'
                              }
                          }
                        ]
                    }
                }
            });

          //$('#actualPaymentsChart').replaceWith($('<canvas id="actualPaymentsChart" max-width="100" max-height="100"></canvas>'));
        },
      createMinimumPaymentsChart()
        {
          let data = {labels:[], datasets:[]};

          for (let i=0; i<this.chartData['min'].date.length; i++)
            { data.labels.push(this.chartData['min'].date[i].toFormat('LL/yy')); }

          data.datasets.push(
            {
              label: 'Balance',
              data: this.chartData['min'].balance,
              type: 'line',
              fill: false,
              borderColor: '#aaaaaa',
              borderWidth: 2,
              yAxisID: 'y-bal'
            });
          data.datasets.push(
            {
              label: 'Interest Paid',
              backgroundColor: '#483D8B',
              data: this.chartData['min'].interestPaid,
              stack: 1,
              yAxisID: 'y-payments'
            });
          data.datasets.push(
            {
              label: 'Principal Paid',
              backgroundColor: '#87cefa',
              data: this.chartData['min'].principalPaid,
              stack: 1,
              yAxisID: 'y-payments'
            });

          // eslint-disable-next-line no-unused-vars
          this.minimumPaymentsChart = new Chart('minimumPaymentsChart', 
            {
              type: 'bar',
              responsive: true,
              maintainAspectRatio: false,
              data: data,
              options: 
                {
                  legend:
                    { display: true },
                  scales: 
                    {
                      xAxes: 
                        [
                          {
                            stacked: true
                          }],
                      yAxes: 
                        [
                          {
                            id: 'y-bal',
                            label: 'Balance',
                            stacked: true,
                            display: true,
                            backgroundColor: '#888888',
                            type: 'linear',
                            position: 'left',
                            scaleLabel: 
                              {
                                display: true,
                                labelString: 'Balance'
                              }
                          },
                          {
                            id: 'y-payments',
                            label: 'Interest and Principal Paid',
                            stacked: true,
                            display: true,
                            backgroundColor: '#888888',
                            type: 'linear',
                            position: 'right',
                            scaleLabel: 
                              {
                                display: true,
                                labelString: 'Interest and Principal Paid'
                              },
                          }
                        ]
                    }
                }
            });
        },
      createTotalInterestChart()
        {
          // eslint-disable-next-line no-unused-vars
          this.totalInterestChart = new Chart('totalInterestChart', 
            {
              type: 'pie', 
              responsive: true,
              maintainAspectRatio: false,
              data: 
                {
                  datasets: 
                    [
                      {
                        data: [Math.round(this.totalInterest*100)/100, this.initialBalance],
                        backgroundColor: ['#aaaaaa', '#6574de']
                      }
                    ],

                  // These labels appear in the legend and in the tooltips when hovering different arcs
                  labels: 
                    [
                      'Total Interest Paid',
                      'Principal Paid'
                    ]
                }
            });
        },
      currencyFormat: function(val)
        { 
          let temp = this.numFormat.format(val);
          return(temp); 
        },
      dateFormat(val)
        { return(val.toFormat('LL/dd/yyyy')); },
      deleteCard: function(index) 
        { 
          this.cards.splice(index, 1);
          this.calc();
        },
      deleteSelected: function()
        {
          for(let i=this.cards.length-1; i>=0; i--)
            {
              if (this.cards[i].form.selected)
                { this.deleteCard(i); }
            }
        },
      getUUID: function ()
        {
          let dt   = new Date().getTime();
          let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) =>
            {
              let r = (dt + Math.random()*16)%16 | 0;
              dt = Math.floor(dt/16);
              return (c=='x' ? r :(r&0x3|0x8)).toString(16);
            });
          return uuid;
        },
      hideCard: function(index)
        { 
          this.cards[index].show = false;
          this.calc();
        },
      saveCards: function()
        { return(null); },
      showCard: function(index)
        { 
          this.cards[index].show = true;
          this.calc();
        },
      toggleSelectAll: function(event)
        {
          let state = event.srcElement.checked;

          for (let card of this.cards)
            { card.form.selected = state; }
        }
    }
}


</script>

<style lang="scss">
  .credit-card {

  }

.table
  {
    font-family: Arial;
    font-size: 10pt;
  }

.table td {
  padding-bottom: 6px !important;
  padding-top: 6px !important;
  padding-right: 8px !important;
}

.table thead th {
  font-size: 10pt;
  padding-top: 10px !important;
  padding-bottom: 5px !important;
}

#tbl-show-cards th, #tbl-show-cards td {
  padding-left: 10px !important;
  padding-right: 0px !important;
}

#tbl-show-cards td, #tbl-show-cards th {
  font-family: Arial;
  font-size: 10pt;
  font-style: normal;
  font-weight: 100;
}

.table-cards tbody > tr:last-child
  { 
    height: 60px;
  }

.card-icon
  {
    cursor: pointer;
    margin-right: 10px;
    margin-top: 2px;
  }

</style>
