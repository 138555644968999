import axios           from 'axios';
import { config }      from '../config/config.js';
import { defineStore } from 'pinia';

export const userStore = defineStore('userStore', 
  {
    // Data
    state: () => (
      { 
        authToken: null,
        cookieTimeoutSeconds: 0,
        useCookies: false,
        username: null 
      }),

    actions:
      {
        async forgotPassword()
          {
            axios.post(`${config.api[process.env.NODE_ENV].uri}/api/user/forgot-password`, this.form.login)
            .then ((reponse) => 
              { console.log('got response'); })
            .catch((err) =>
              { console.log('found error'); });
          },
        async login()
          {
            axios.post(`${config.api[process.env.NODE_ENV].uri}/api/user/login`, this.form.login)
            .then ((response) => 
              { 
                if (response.data.status == 'success')
                  { this.authToken = response.data.authToken; }
                console.log('got response'); 
              })
            .catch((err) =>
              { console.log('found error'); });
          },
        async register()
          {
            axios.post(`${config.api[process.env.NODE_ENV].uri}/api/user/register`, this.form.register)
            .then ((reponse) => 
              { console.log('got response'); })
            .catch((err) =>
              { console.log('found error'); });
          },

      },

    // Getters
    getters: 
      {
        authToken: (state)            => state.authToken,
        cookieTimeoutSeconds: (state) => state.cookieTimeoutSeconds,
        useCookies: (state)           => state.useCookies,
        username: (state)             => state.username
      }
  });