<template lang="html">

  <section class="header">
    
    <div>

        <!-- Modal -->
        <!-- <div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document"> -->
            <!-- <div class="modal-content"> -->
              <!-- <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Login</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                
                <div class="row">
                    <div class="col">
                        <div class="card border rounded-0 shadow">
                            <div class="card-body shadow-sm">
                                <p class="card-text text-left" style="color: #555555;">If you have an account, please log in with one of the following....</p>
                                <form style="margin-top: 20px;" action="https://localhost:3000/login">
                                    <div class="form-row">
                                        <div class="col d-flex justify-content-center justify-content-sm-center justify-content-xl-center">
                                          <img src="img/facebook-logo.png" height="32px" style="width: 48px;height: 48px;margin-right: 10px;">
                                          <img src="img/google-logo.png" height="32px" style="width: 48px;height: 48px;margin-right: 10px;">
                                          <img src="img/linkedin-logo.png" height="32px" style="width: 48px;height: 48px;">
                                        </div>
                                    </div>
                                    <p class="d-flex d-sm-flex d-xl-flex justify-content-center justify-content-sm-center justify-content-xl-center" style="font-size: 10pt;margin-top: 11px;color: rgb(129,129,129);">or</p><input class="form-control form-control-sm" type="text" name="username" placeholder="username" style="margin-top: 15px;"><input class="form-control form-control-sm" type="password" placeholder="password" name="password"
                                        style="margin-top: 10px;">
                                    <div class="form-row">
                                        <div class="col d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-center"><button class="btn btn-primary btn-sm" type="button" style="margin-top: 20px;margin-right: 10px;">&nbsp; &nbsp;Sign in&nbsp; &nbsp;</button></div>
                                    </div>
                                    <div class="form-row" style="margin-top: 15px;font-size: 10pt;">
                                        <div class="col d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-xl-center"><a href="#/create-account" style="margin-right: 7px;">Create account</a><span style="margin-right: 7px;">or</span><a href="#/reset-password">reset password</a></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

              </div> -->

              <LoginRegister ref="loginRegister" id="loginRegister"/>
              <!-- <div class="modal-content wrapper" :class="{active: wrapperActive, 'active-popup': wrapperActivePopup}">
                <span @click="closePopup" class="icon-close"><ion-icon name="close"></ion-icon></span>
                <div class="form-box login">
                  <h2>Login</h2>
                  <form>
                    <div class="input-box">
                      <input name="username" type="text" v-model="form.login.username" required>
                      <span class="icon"><ion-icon name="person"></ion-icon></span>
                      <label>Username</label>
                    </div>
                    <div class="input-box">
                      <input name="password" type="password" v-model="loginPassword" required>
                      <span class="icon"><ion-icon name="lock-closed"></ion-icon></span>
                      <label>Password</label>
                    </div>
                    <div class="remember-forget">
                      <label><input name="rememberme" type="checkbox" v-model="form.login.rememberMe">Remember me</label>
                      <a @click="forgotPassword">Forgot Password?</a>
                    </div>
                    <button class="btn" type="submit" @click="login">Login</button>
                    <div class="login-register">
                      <p>Don't have an account? &nbsp;<a href="#" @click="showRegisterForm" class="register-link">Register</a></p>
                    </div>
                  </form>
                </div>
                <div class="form-box register">
                  <h2>Registration</h2>
                  <form>
                    <div class="input-box">
                      <input name="username" type="text" v-model="form.register.username" required>
                      <span class="icon"><ion-icon name="person"></ion-icon></span>
                      <label>Username</label>
                    </div>
                    <div class="input-box">
                      <input name="email" type="email" v-model="form.register.email" required>
                      <span class="icon"><ion-icon name="mail"></ion-icon></span>
                      <label>Email</label>
                    </div>
                    <div class="input-box">
                      <input name="password" type="password" required>
                      <span class="icon"><ion-icon name="lock-closed"></ion-icon></span>
                      <label>Password</label>
                    </div>
                    <div class="input-box">
                      <input name="password2" type="password" required>
                      <span class="icon"><ion-icon name="lock-closed"></ion-icon></span>
                      <label>Confirm Password</label>
                    </div>
                    <div class="remember-forget">
                      <label><input name="agree" type="checkbox">I agree to the terms and conditions</label>
                    </div>
                    <button class="btn" @click="register">Register</button>
                    <div class="login-register">
                      <p>Already have an account? &nbsp;<a href="#" @click="showLoginForm" cclass="login-link">Login</a></p>
                    </div>
                  </form>
                </div>
              </div> -->

            <!-- </div> -->
          <!-- </div>
        </div> -->

        <div class="container">
            <!-- <nav class="navbar navbar-light navbar-expand-lg navigation-clean">
                <div class="container"><a class="navbar-brand" href="#" style="font-size: 18pt;font-family: 'Alegreya Sans SC', sans-serif;font-weight: normal;font-style: normal;">Canterberry Creative Arts</a><button data-toggle="collapse" class="navbar-toggler" data-target="#navcol-1"><span class="sr-only">Toggle navigation</span><span class="navbar-toggler-icon"></span></button>
                    
                    <div class="collapse navbar-collapse" id="navcol-1">
                        <ul class="nav navbar-nav ml-auto" id="main-menu" style="font-size: 11pt;">
                            <li class="nav-item" role="presentation"><a class="nav-link active" href="#">Investments</a></li>
                            <li class="dropdown nav-item"><a class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="#">Planning&nbsp;</a>
                                <div class="dropdown-menu" role="menu" style="font-size: 11pt;"><a class="dropdown-item" role="presentation" href="#">College</a><a class="dropdown-item" role="presentation" href="#">Mortgage</a><a class="dropdown-item" role="presentation" href="#">Retirement</a></div>
                            </li>
                        </ul>
                        <ul class="nav navbar-nav ml-auto" id="main-menu" style="font-size: 8pt;">
                            <li id="login-reg" class="nav-item" role="presentation" @click="showLogin()"><a class="nav-link active" href="#">Login/Register</a></li>
                        </ul>
                  </div> -->


            <!-- <b-navbar toggleable="lg" type="light" style="padding-left: 0px; padding-right: 0px;">
                <b-navbar-brand href="#/">
                  <a class="navbar-brand" href="#/" style="font-size: 18pt;font-family: 'Alegreya Sans SC', sans-serif;font-weight: normal;font-style: normal;">Canterberry Creative Arts</a>
                </b-navbar-brand>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                  <b-navbar-nav class="ml-auto">
                    <b-nav-item href="#">Investments</b-nav-item>
                    <b-nav-item href="#">Financial Planning</b-nav-item>
                    <b-nav-item-dropdown text="Tools" right>
                      <b-dropdown-item href="#/calculators/car-loans">Car Loan Calculator</b-dropdown-item>
                      <b-dropdown-item href="#/calculators/college-loans">College Loan Calculator</b-dropdown-item>
                      <b-dropdown-item href="#/calculators/credit-cards">Credit Card Calculator</b-dropdown-item>
                      <b-dropdown-item href="#/calculators/mortgages">Mortgage Calculator</b-dropdown-item>
                    </b-nav-item-dropdown>
                  </b-navbar-nav> -->

                  <!-- Right aligned nav items -->
                  <!-- <b-navbar-nav class="ml-auto">
                    <a class="nav-link active" href="#" @click="showLogin()" style="color: #888888;font-size: 10pt;font-family: 'Alegreya Sans SC', sans-serif;font-weight: normal; font-style: normal;">Login/Register</a>
                  </b-navbar-nav>
                </b-collapse>
              </b-navbar> -->

              <nav class="navbar navbar-expand-lg bg-body-tertiary">
                <div class="container-fluid">
                  <a class="navbar-brand" href="#/" style="font-size: 18pt;font-family: 'Alegreya Sans SC', sans-serif;font-weight: normal;font-style: normal;">Canterberry Creative Arts</a>
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                      <li class="nav-item">
                        <a class="nav-link" aria-current="page" href="#">Investments</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Financial Planning</a>
                      </li>
                      <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Tools
                        </a>
                        <ul class="dropdown-menu">
                          <li><router-link class="dropdown-item" to="/calculators/car-loans">Car Loan Calculator</router-link></li>
                          <li><router-link class="dropdown-item" to="/calculators/college-loans">College Loan Calculator</router-link></li>
                          <li><router-link class="dropdown-item" to="/calculators/credit-cards">Credit Card Calculator</router-link></li>
                          <li><router-link class="dropdown-item" to="/calculators/mortgages">Mortgage Calculator</router-link></li>
                          <!-- <li><hr class="dropdown-divider"></li> -->
                          <!-- <li><a class="dropdown-item" href="#">Something else here</a></li> -->
                        </ul>
                      </li>
                    </ul>
                    <a id="login-register" class="nav-link" @click="$refs.loginRegister.show">Login/Register</a>
                    <!-- <form class="d-flex" role="search">
                      <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                      <button class="btn btn-outline-success" type="submit">Search</button>
                    </form> -->
                  </div>
                </div>
              </nav>

                    
                <!-- </div>
            </nav> -->
        </div>

    </div>

  </section>

</template>

<script lang="js">
  import $             from 'jquery/dist/jquery.js';
  import {Modal}       from 'bootstrap';
  import LoginRegister from '@/components/LoginRegister.vue';
  // import { mapActions, mapState, mapWritableState } from 'pinia';
  // import sha256                                     from 'crypto-js/sha256';
  //import userStore                                  from '../stores/userStore.js';


  export default  {
    name: 'header',
    props: 
      [
      ],
    components: 
      {
        LoginRegister
      },
    mounted () 
      {
        //this.loginModal = new Modal('#loginModal', {backdrop: 'false'}); //('#loginModal', {});
        //console.log('Created modal');
        // const wrapper      = document.querySelector('.wrapper');
        // const loginLink    = document.querySelector('.login-link');
        // const registerLink = document.querySelector('.register-link');
        // const iconClose    = document.querySelector('.icon-close');

        // registerLink.addEventListener('click', () => {
        //   wrapper.classList.add('active');
        // });

        // loginLink.addEventListener('click', () => {
        //   wrapper.classList.remove('active');
        // });

        // iconClose.addEventListener('click', () => {
        //   wrapper.classList.remove('active-popup');
        // });
      },
    data () 
      {
        return {
          //loginModal: null
          // form:
          //   {
          //     login:
          //       {
          //         passwordHash: null,
          //         rememberMe: false,
          //         username: null,
          //       },
          //     register:
          //       {
          //         email: null,
          //         passwordHash: null,
          //         username: null
          //       }
          //   },
          // loginPassword: null,
          // registerPassword: null,
          // registerPassword2: null,
          // loginActive: true,
          // registerActive: false,
          // wrapperActive: false,
          // wrapperActivePopup: false
        }
      },
    methods: 
      {
        // showLoginForm()
        //   { this.wrapperActive = false; },
        // showRegisterForm()
        //   { this.wrapperActive = true; },
        // closePopup()
        //   //{ this.activeWrapperPopup = false; },
        //   {
        //     $('#loginModal').modal('hide')
        //   },
        // getPasswordHash: function(password)
        //   { return(sha256(password).toString()); },
        // //...mapActions(userStore, ['forgotPassword', 'login', 'register']),
        //showLoginRegister: function()
          //{ 
            //this.$refs.loginRegister.show();
            //this.$el.querySelector("#loginModal").modal('show');
            //$('#loginModal').modal('show'); 
          //}
      },
    computed:
      {
        //...mapWritableState(userStore, { useCookies: 'useCookies' }),
        //...mapWritableState(userStore, { username: 'username' })
      },
    watch:
      {
        // useCookies()
        //   {

        //   },
        // loginPassword()
        //   { this.form.login.passwordHash = this.getPasswordHash(this.loginPassword); },
        // registerPassword() 
        //   { 
        //     if (this.registerPassword == this.registerPassword2) 
        //       { this.form.register.passwordHash = this.getPasswordHash(this.registerPassword); }
        //   },
        // registerPassword2() 
        //   { 
        //     if (this.registerPassword == this.registerPassword2) 
        //       { this.form.register.passwordHash = this.getPasswordHash(this.registerPassword); }
        //   }
      }
}


</script>

<style scoped lang="scss">
//   .header {

  // }

// * { 
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
//   font-family: 'Poppins', sans-serif;
// }

// body {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 50vh;
//   background: url('../../public/confirm-email/assets/img/prairie-storm.png') no-repeat;
//   background-size: cover;
//   background-position: center;
// }

// header {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   padding: 20px 100px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   z-index: 99;
// }

// .logo {
//   font-size: 2em;
//   color: #fff;
//   user-select: none;
// }

// .navigation a {
//   position: relative;
//   font-size: 1.1em;
//   color: #fff;
//   text-decoration: none;
//   font-weight: 500;
//   margin-left: 40px;
//   transition: .5s;
// }

// .navigation a::after {
//   content: '';
//   position: absolute;
//   left: 0;
//   bottom: -6px;
//   width: 100%;
//   height: 3px;
//   background: #fff;
//   border-radius: 5px;
//   transform: scaleX(0);
//   transform-origin: right;
//   transition: transform .5s;
// }

// .navigation a:hover::after {
//   transform-origin: left;
//   transform: scaleX(1);
// }

// .navigation .btnLogin-popup {
//   width: 130px;
//   height: 50px;
//   background: transparent;
//   border: 2px solid #fff;
//   outline: none;
//   border-radius: 6px;
//   cursor: pointer;
//   font-size: 1.1em;
//   color: #fff;
//   font-weight: 500;
//   margin-left: 40px;
// }

// .navigation .btnLogin-popup:hover {
//   background: #fff;
//   color: #162938;
// }

// .wrapper {
//   position: relative;
//   width: 400px;
//   height: 440px;
//   // background: transparent;
//   background: white;
//   border: 2px solid rgba(255,255,255,.5);
//   border-radius: 20px;
//   backdrop-filter: blur(20px);
//   box-shadow: 0 0 30px rgba(0,0,0,.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
//   // transform: scale(0);
//   transition: transform .5s ease, height .2s ease;
//   margin-top: 200px;
// }

// .modal-dialog {
//   align-items: center;
//   display: flex;
//   height: 100%;
// }

// .wrapper.active-popup {
//   transform: scale(1);
// }

// .wrapper.active {
//   height: 600px;
// }

// .wrapper .form-box {
//   width: 100%;
//   padding: 40px;
// }

// .wrapper .form-box.login {
//   transition: transform .18s ease;
//   transform: translateX(0);
// }

// .wrapper.active .form-box.login {
//   transition: none;
//   transform: translateX(-400px);
// }

// .wrapper .form-box.register {
//   position: absolute;
//   transition: none;
//   transform: translateX(400px);
// }

// .wrapper.active .form-box.register {
//   transition: transform .18s ease;
//   transform: translateX(0);
// }

// .wrapper .icon-close {
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   width: 45px;
//   height: 45px;
//   background: #162938;
//   font-size: 1em;
//   color: #fff;
//   display: flex;
//   justify-content: center;
//   align-items:center;
//   border-bottom-left-radius: 20px;
//   cursor: pointer;
//   z-index: 1
// }
// .form-box h2 {
//   font-size: 2em;
//   color: #162938;
//   text-align: center;
// }

// .input-box {
//   position: relative;
//   width: 100%;
//   height: 50px;
//   border-bottom: 2px solid #162938;
//   margin: 30px 0;
// }

// .input-box label {
//   position: absolute;
//   top: 50%;
//   left: 35px;
//   transform: translateY(-50%);
//   font-size: 1em;
//   color: #162938;
//   font-weight: 500;
//   pointer-events: none;
//   transition: .5s;
// }

// .input-box input:focus~label,
// .input-box input:valid~label {
//   top: -2px;
// }

// .input-box input:focus~span,
// .input-box input:valid~span {
//   top: -1px;
// }

// .input-box input {
//   width: 100%;
//   height: 100%;
//   background: transparent;
//   border: none;
//   outline: none;
//   font-size: 1em;
//   color: #162938;
//   font-weight: 600;
//   padding: 0 35px 0 5px;
// }

// .input-box .icon {
//   position: absolute;
//   left: 8px;
//   font-size: 1.2em;
//   color: #162938;
//   line-height: 57px;
//   top: 50%;
//   transform: translateY(-50%);
//   transition: .5s;
// }

// .remember-forget {
//   font-size: .9em;
//   color: #162938;
//   font-weight: 500;
//   margin: -15px 0 15px;
//   display: flex;
//   justify-content: space-between;
// }

// .remember-forget label input {
//   accent-color: #162938;
//   margin-right: 8px;
// }

// .remember-forget a {
//   color: #162938;
//   text-decoration: none;
// }

// .remember-forget a:hover {
//   text-decoration: underline;
// }

// .btn {
//   width: 100%;
//   height: 35px;
//   background: #28445a;
//   border: none;
//   outline: none;
//   border-radius: 6px;
//   cursor: pointer;
//   font-size: 1em;
//   color: #fff;
//   font-weight: 200;
// }
.container-fluid {
  padding: 0px;
}

#login-register:hover {
 cursor: pointer;
}

#login-register {
  font-size: .9em;
  color: #162938;
  text-align: center;
  font-weight: 500;
}

#login-register p a {
  color: #162938;
  text-decoration: none;
  font-weight: 600;
}

#login-register p a:hover {
  text-decoration: underline;
}

.nav-link {
  color: black;
}

.navbar-nav {
  margin-left: 20px;
}

// .dropdown-menu {
//   padding: 12px;
// }


</style>
