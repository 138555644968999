<template lang="html">

  <section class="src-views-college-loan">
    <h1>college-loan-calculator</h1>
    <!-- <input id="datepicker" width="160" style="font-size: 10pt; height: 30px"/> -->
    <!-- <date-pick v-model="date" :format="'YYYY.MM.DD'"></date-pick> -->
    <!-- <div class="col-sm-5">
      <vue-datetime-picker class="vue-picker3" name="picker3"
                           :model.sync="result3"
                           type="date"
                           language="en-US"
                           date-format="L">
      </vue-datetime-picker>
    </div> -->
    <VueDatePicker v-model="time1" valueType="format"></VueDatePicker>
  </section>

</template>

<script lang="js">

// import DatePick from 'vue-date-pick/dist/vueDatePick.js';
// import 'vue-date-pick/dist/vueDatePick.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


  export default  {
    name: 'college-loan-calculator',
    props: [],
    components: 
      {
        // DatePick,
        // "vue-datetime-picker": require("vue-datetime-picker")
        VueDatePicker
      },
    // mounted: function () 
    //   {
    //     // eslint-disable-next-line no-undef
    //     $('#datepicker').datepicker({ uiLibrary: 'bootstrap4', value: this.date });

    //     //this.calc();

    //     // eslint-disable-next-line no-undef
    //     $('#datepicker').datepicker({ uiLibrary: 'bootstrap4' });
    //   },
    data () {
      return {
        date: '2009.01.01', //new Date('3/3/1967')
        result3: null
      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .college-loan-calculator {

  }
</style>
