<template lang="html">

  <section class="footer">
    
    <div class="footer-dark">
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-3 item text-left">
                        <h3>Services</h3>
                        <ul>
                            <li><a href="#">Education</a></li>
                            <li><a href="#">Finances</a></li>
                            <li><a href="#">Mortgage</a></li>
                            <li><a href="#">Retirement</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-6 col-md-3 item text-left">
                        <h3>About</h3>
                        <ul>
                            <li><a href="#">Company</a></li>
                            <li><a href="#">Team</a></li>
                            <li><a href="#">Careers</a></li>
                        </ul>
                    </div>
                    <div class="col-md-6 item text text-left">
                        <h3>Canterberry Creative Arts</h3>
                        <p style="font-size: 14px;">Canterberry Creative Arts provides a comprehensive set of financial planning tools to help in understanding your finances, plan for life events and develop long-range plans to prepare for your retirement.</p>
                    </div>
                    <div class="col item social"><a href="#"><i class="icon ion-social-facebook"></i></a><a href="#"><i class="icon ion-social-twitter"></i></a><a href="#"><i class="icon ion-social-snapchat"></i></a><a href="#"><i class="icon ion-social-instagram"></i></a></div>
                </div>
                <p class="copyright">Canterberry Creative Arts © 2017</p>
            </div>
        </footer>
    </div>

  </section>

</template>

<script lang="js">

  export default  {
    name: 'footer',
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .footer {

  }
</style>
