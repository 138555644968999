<template lang="html">

  <section class="education">
    
    <div class="article-list">
        <div class="container" id="main-container"><img class="img-fluid shadow" id="lookout-header" src="img/campus3.jpg">
            <div class="row justify-content-center articles" style="margin-top: 15px;font-family: 'Alegreya Sans SC', sans-serif;">
                <div class="col">
                    <ol class="breadcrumb" style="background-color: rgb(255,255,255);font-family: Actor, sans-serif;font-size: 12pt;color: rgb(55,59,62);">
                        <li class="breadcrumb-item"><a href="#/"><span style="color: rgb(118,119,119);">Home</span></a></li>
                        <li class="breadcrumb-item"><a href="#"><span style="color: rgb(118,119,119);">Education</span></a></li>
                    </ol>
                    <div>
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" role="presentation"><a class="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#tab-1">Is College Right For Me?</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-2">Choosing A College</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-3">Costs</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-4">Financing</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-5">Alternatives to College</a></li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active text-start" role="tabpanel" id="tab-1">
                                <p style="font-size: 12pt;font-family: Average, serif;color: rgb(0,0,0);"><br>The decision to attend college or, atlernately, pursue a career that requires different qualifications such as an industry-specific certification or hands-on training is one that should be carefully considered. &nbsp;Some of the factors that should be evaluated are:&nbsp;</p>
                                <p style="font-size: 16pt;letter-spacing: 0px;font-family: 'Alegreya Sans SC', sans-serif;margin-left: 10px;color: rgb(0,0,0);">Interest</p>
                                <p style="font-family: Average, serif;margin-left: 25px;font-size: 12pt;color: rgb(0,0,0);">First and foremost, one should ask, "what am I really interested in?" &nbsp;When answering this question, be honest. &nbsp;Selecting a course of action based on someone else's expectations or a perceived, but not heart-felt, interest, is never a good idea. &nbsp;Investing the time, energy and financial resources in an endeavor for the wrong reasons makes it all that much harder to right your course and pursue that which will be truely rewarding.<br><br>Not everyone knows what career they want to spend the rest of their life pursuing when they graduate from High School. &nbsp;However, changing direction after several years of training or study may have significant consequences. &nbsp;Not only is the time invested in the training or study lost, but the new direction of interest may require starting completely over. &nbsp;This will likely place an enormous financial burden on the individual and could very well result in putting off significant life events such as buying a house or getting married until the debt can be paid down. &nbsp;Colleges offer degrees in many disciplines and can provide an excellent environment for individuals to explore their passions and interests and help them decide if they ultimately want to pursue a degree and, if so, in which field.&nbsp;<br></p>
                                <p style="font-size: 16pt;letter-spacing: 0px;font-family: 'Alegreya Sans SC', sans-serif;margin-left: 10px;color: rgb(0,0,0);">Suitability</p>
                                <p style="font-family: Average, serif;margin-left: 25px;font-size: 12pt;color: rgb(0,0,0);">What kind of job will a college degree qualify me for? &nbsp;Will it be a physically demanding or mentally demanding job? &nbsp;Will it require that I frequently interact with people, or will I work in an individual contributor-type of role? &nbsp; &nbsp;These questions, and many more like them, are often overlooked when deciding on a field of study, but they are essential in determining the long-term suitability of an academic degree. &nbsp;Too many people ultimately decide that they "can't stand" their job or that they're not "made to do this". &nbsp;Those that choose to pursue a different career often need to be re-trained or re-educated. &nbsp;Often, this could be avoided by asking these types of suitability questions.<br></p>
                                <p style="font-size: 16pt;letter-spacing: 0px;font-family: 'Alegreya Sans SC', sans-serif;margin-left: 10px;color: rgb(0,0,0);">Earnings Potential</p>
                                <p style="font-family: Average, serif;margin-left: 25px;font-size: 12pt;color: rgb(0,0,0);">College is not cheap, and depending on whether you're looking at a private school, rather than a public school, it may be prohibitively expensive.&nbsp;&nbsp;<br></p>
                                <p style="font-size: 16pt;letter-spacing: 0px;font-family: 'Alegreya Sans SC', sans-serif;margin-left: 10px;color: rgb(0,0,0);">Commitment</p>
                                <p style="font-family: Average, serif;margin-left: 25px;font-size: 12pt;color: rgb(0,0,0);">Well-paying jobs pay well for the simple fact that it takes time to acquire the knowledge and/or skills that are necessary to achieve a certain level of competence in a given trade or profession.&nbsp;<br></p>
                            </div>
                            <div class="tab-pane text-start" role="tabpanel" id="tab-2">
                                <p style="font-family: Average, serif;font-size: 12pt;color: rgb(0,0,0);"><br><span style="color: rgb(0, 0, 0);">A college can be a complex and daunting process, but there are several key factors you can consider to help you make an informed decision. Here are some steps you can follow:</span><br></p>
                                <ol list-style-position="inside" type="1" style="font-family: Average, serif;font-size: 12 pt;color: rgb(0,0,0);">
                                    <li><b>Determine your priorities:</b> What is most important to you in a college experience? This may include location, size, campus culture, academic programs, cost, student life, and career opportunities.</li>
                                    <li><b>Research colleges:</b> Use online resources such as college websites, college guidebooks, and college comparison websites to gather information about colleges that meet your criteria.</li>
                                    <li><b>Narrow down your list:</b> Based on your research, create a shortlist of colleges that are a good fit for you.</li>
                                    <li><b>Visit colleges:</b> If possible, visit the colleges on your shortlist. Take a tour, attend an information session, and talk to current students, faculty, and staff.</li>
                                    <li><b>Consider the cost:</b> Review the total cost of attendance, including tuition, room and board, and other expenses. Also, consider the availability of financial aid, scholarships, and grants.</li>
                                    <li><b>Apply:</b> Submit applications to your top colleges, making sure to follow their individual application requirements and deadlines.</li>
                                    <li><b>Make your decision:</b> After you have received your acceptance letters and financial aid offers, consider all the factors you have researched and evaluated, and choose the college that is the best fit for you.</li>
                                </ol>
                                <p style="font-family: Average, serif;font-size: 12pt;color: rgb(0,0,0);">Remember, the most important thing is to choose a college that aligns with your goals and priorities, and where you will have the best chance to succeed and thrive.<br></p>
                            </div>
                            <div class="tab-pane text-start" role="tabpanel" id="tab-3">
                                <p style="font-family: Average, serif;font-size: 12pt;color: rgb(0,0,0);"><br>Cost is one of the most important factors to consider when choosing a college, as it can have a significant impact on a student's financial stability and future prospects. The cost of attendance can include:<br></p>
                                <ul>
                                    <li style="font-family: Average, serif;"><span style="color: rgb(0, 0, 0);">Tuition</span></li>
                                    <li style="font-family: Average, serif;"><span style="color: rgb(0, 0, 0);">Administrative fees</span></li>
                                    <li style="font-family: Average, serif;">Room and Board</li>
                                    <li style="font-family: Average, serif;"><span style="color: rgb(0, 0, 0);">Books and supplies</span></li>
                                    <li style="font-family: Average, serif;"><span style="color: rgb(0, 0, 0);">Transportation</span><br></li>
                                    <li style="font-family: Average, serif;"><span style="color: rgb(0, 0, 0);">Other personal expenses</span><br></li>
                                </ul>
                                <p style="font-family: Average, serif;font-size: 12pt;color: rgb(0,0,0);">The cost of a college can vary greatly between institutions, and students should carefully consider the full range of costs and the financial aid options available to them before making a decision. Some colleges and universities may offer scholarships, grants, and other forms of financial aid to help offset the cost of attendance, while others may offer flexible payment plans or other financing options.<br></p>
                                <p style="font-family: Average, serif;font-size: 12pt;color: rgb(0,0,0);">In addition to considering the cost of attendance, students should also think about their long-term financial goals, such as their future career prospects and earning potential. For some students, the cost of a more expensive college may be worth it if it provides access to valuable resources and networks that can help them succeed in their chosen field.<br></p>
                                <p style="font-family: Average, serif;font-size: 12pt;color: rgb(0,0,0);">Ultimately, the cost of a college should be balanced against its value, and students should make their decision based on their individual goals, priorities, and financial situation.<br></p>
                            </div>
                            <div class="tab-pane text-start" role="tabpanel" id="tab-4">
                                <p style="font-family: Average, serif;font-size: 12pt;color: rgb(0,0,0);"><br>Financing a college education can be a challenge, but there are several options available to help make it more manageable. Here are some of the most common ways to finance a college education:<br></p>
                                <ul>
                                    <li style="font-family: Average, serif;"><b>Scholarships and grants:</b> Scholarships and grants are forms of financial aid that do not need to be repaid. They are often based on merit, such as academic achievement or athletic ability, or on financial need.<br></li>
                                    <li style="font-family: Average, serif;"><b>Federal student loans:</b> Federal student loans are low-interest loans that are available to eligible students through the government. They typically have more favorable terms than private loans, and some types of federal loans have flexible repayment options.<br></li>
                                    <li style="font-family: Average, serif;"><b>Private student loans:</b> Private student loans are offered by banks and other financial institutions, and they often have higher interest rates and less flexible repayment options than federal student loans.</li>
                                    <li style="font-family: Average, serif;"><b>Work-study programs:</b> Work-study programs are federal financial aid programs that provide part-time jobs to eligible students, allowing them to earn money to help pay for their education.<br></li>
                                    <li style="font-family: Average, serif;"><b>Saving and budgeting:</b> Building a savings plan and budgeting effectively can help students pay for college without relying on loans or other forms of financial aid. Students can also consider working part-time or taking on a summer job to help pay for their education.<br></li>
                                    <li style="font-family: Average, serif;"><b>Family contributions:</b> Some families choose to contribute to their child's college education through savings, gifts, or loans. It is important to have an open and honest conversation with your family about their expectations and ability to contribute to your education.<br></li>
                                </ul>
                                <p style="font-family: Average, serif;font-size: 12pt;color: rgb(0,0,0);">It is important to carefully consider all of your options and to exhaust all forms of free financial aid, such as scholarships and grants, before taking out loans. It is also important to understand the terms and conditions of any loans you take out, including the interest rate, repayment terms, and total cost of the loan. A financial aid counselor or advisor at your college can help you understand your options and make a plan to finance your education.<br></p>
                            </div>
                            <div class="tab-pane text-start" role="tabpanel" id="tab-5">
                                <p style="font-family: Average, serif;font-size: 12pt;color: rgb(0,0,0);"><br>There are several alternatives to traditional college education, and each option has its own set of benefits and drawbacks. Here are a few of the most common alternatives:<br></p>
                                <ul>
                                    <li style="font-family: Average, serif;"><b>Online education:</b> With the advent of technology, online education has become an increasingly popular alternative to traditional college. Online programs can offer flexibility, lower costs, and the ability to learn at your own pace.<br></li>
                                    <li style="font-family: Average, serif;"><b>Trade schools and vocational schools:</b> Trade schools and vocational schools offer hands-on training and practical education in specific fields such as plumbing, welding, or cosmetology. These programs can provide students with the skills and experience needed to enter their desired field, often in a shorter amount of time than a traditional college program.<br></li>
                                    <li style="font-family: Average, serif;"><b>Apprenticeships:</b> Apprenticeships are programs that allow students to earn while they learn by working with experienced professionals in their field of interest. Apprenticeships can provide students with the hands-on experience they need to succeed in their chosen career.</li>
                                    <li style="font-family: Average, serif;"><b>Bootcamps:</b> Bootcamps are intensive, short-term programs that offer training in a specific technical skill, such as coding or data analysis. These programs can be a great option for students who want to quickly gain the skills and experience needed to enter a new field.<br></li>
                                    <li style="font-family: Average, serif;"><b>Self-education:</b> Some individuals choose to forgo formal education and instead opt for self-education through books, online resources, and hands-on experience. This approach can be a good fit for those who are self-motivated, disciplined, and have a strong passion for learning.<br></li>
                                </ul>
                                <p style="font-family: Average, serif;font-size: 12pt;color: rgb(0,0,0);">It is important to consider your goals, interests, and financial situation when choosing an alternative to traditional college education. Researching each option and speaking with professionals in your desired field can also be helpful in making an informed decision.<br></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  </section>

</template>

<script lang="js">

  export default  {
    name: 'education',
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .education {

  }
  .tab-content p
   {
     color: #444444;
   }
</style>
