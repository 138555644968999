<template lang="html">

  <section class="car-loan">
    
    
    <div class="article-list">
        <div class="container" id="main-container"><img class="img-fluid shadow" id="lookout-header" src="/img/car-loan.jpg">
            <div class="row justify-content-center articles" style="margin-top: 15px;font-family: 'Alegreya Sans SC', sans-serif;">
                <div class="col">
                    <ol class="breadcrumb" style="background-color: rgb(255,255,255);font-family: Actor, sans-serif;font-size: 12pt;color: rgb(55,59,62);">
                        <li class="breadcrumb-item"><a href="#/"><span style="color: rgb(118,119,119);">Home</span></a></li>
                        <li class="breadcrumb-item"><a href="#"><span style="color: rgb(118,119,119);">Car Loan Calculator</span></a></li>
                    </ol>
                </div>
            </div>
            <div class="row justify-content-center articles" style="font-family: 'Alegreya Sans SC', sans-serif;margin-top: -40px;">
                <div class="col" style="max-width: 360px; min-width: 360px; background-color: #f4f4f4;font-family: Arial;font-size: 10pt;">
                    <div class="row" style="padding-top: 10px;padding-bottom: 10px;">
                        <div class="col text-left">
                            <h6>Sale Information</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-right" style="margin-right: -10px;margin-top: 8px;">
                            <p>Vehicle Price:</p>
                        </div>
                        <div class="col" style="margin-left: -10px;">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="vehiclePrice" v-model="vehiclePrice" class="form-control" type="text">
                                <div class="input-group-append"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-right" style="margin-right: -10px;margin-top: 8px;">
                            <p>Down Payment:</p>
                        </div>
                        <div class="col" style="margin-left: -10px;">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="downPayment" v-model="downPayment" class="form-control" type="text">
                                <div class="input-group-append"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-right" style="margin-right: -10px;margin-top: 8px;">
                            <p>Trade-in Value:</p>
                        </div>
                        <div class="col" style="margin-left: -10px;">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="tradeinValue" v-model="tradeinValue" class="form-control" type="text">
                                <div class="input-group-append"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-right" style="margin-right: -10px;margin-top: 8px;">
                            <p>Owed on Trade-in:</p>
                        </div>
                        <div class="col" style="margin-left: -10px;">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="owedOnTradein" v-model="owedOnTradein" class="form-control" type="text">
                                <div class="input-group-append"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row" id="license-fees-row">
                        <div class="col text-right" style="margin-right: -10px;margin-top: 8px;">
                            <p style="margin-top: -8px;">Other Financed Fees:</p>
                            <p style="font-size: 7pt; margin-botton: 0px; margin-top: -1px;">(Registration, Licensing, Dealer Fees)</p>
                        </div>
                        <div class="col" style="margin-left: -10px;">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="licensingFees" v-model="licensingFees" class="form-control" type="text">
                                <div class="input-group-append"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row" id="sales-tax-row" style="margin-top: -2px;">
                        <div class="col text-right" style="margin-right: -10px;margin-top: 8px;">
                            <p>Sales Tax:</p>
                        </div>
                        <div class="col" style="margin-left: -10px;">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="salesTax" v-model="salesTax" class="form-control" type="text">
                                <div class="input-group-append"><span class="input-group-text">%</span></div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="padding-top: 10px;padding-bottom: 10px;">
                        <div class="col text-left">
                            <h6>Loan Information</h6>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 8px;">
                        <div class="col text-right" style="margin-right: -10px;margin-top: 8px;">
                            <p>Loan Amount:</p>
                        </div>
                        <div class="col" style="margin-left: -10px;">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="loanAmount" v-model="loanAmount" class="form-control" type="text" disabled>
                                <div class="input-group-append"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row" id="sales-tax-row-1">
                        <div class="col text-right" style="margin-right: -10px;margin-top: 8px;">
                            <p>Interest Rate:</p>
                        </div>
                        <div class="col" style="margin-left: -10px;">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="rate" v-model="rate" class="form-control" type="text">
                                <div class="input-group-append"><span class="input-group-text">%</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 8px;">
                        <div class="col text-right" style="margin-right: -10px;margin-top: 8px;">
                            <p>Term:</p>
                        </div>
                        <div class="col" style="margin-left: -10px;">
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend"></div><input v-validate="'numeric'" name="term" v-model="term" class="form-control" type="text">
                                <div class="input-group-append"><span class="input-group-text">mo</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 8px;">
                        <div class="col text-right" style="margin-right: -10px;margin-top: 8px;">
                            <p>Start Date:</p>
                        </div>
                        <div class="col" style="margin-left: -10px;">


                            <!-- <div class="input-group input-group-sm">
                                <div class="input-group-prepend"></div><input v-model="startDate" class="form-control" type="text">
                                <div class="input-group-append"><span class="input-group-text">Text</span></div>
                            </div> -->
                            <!-- <div class='input-group input-group-sm date' id='datetimepicker1'>
                              <input type='text' class="form-control" />
                              <span class="input-group-addon">
                                  <span class="glyphicon glyphicon-calendar"></span>
                              </span>
                            </div> -->


                                  <!-- <vue-datetime-picker class="vue-picker3" name="picker3"
                                                      :model.sync="startDate"
                                                      type="date"
                                                      language="en-US"
                                                      date-format="L">
                                  </vue-datetime-picker> -->

                                  <!-- <template>
    <date-pick v-model="startDate"></date-pick>
</template> -->
                          <input id="datepicker" width="160" style="font-size: 10pt; height: 30px" @change="setDate"/>


                        </div>
                    </div>
                    <div class="row" style="padding-bottom: 20px;">
                        <div class="col text-right" style="margin-right: -10px;margin-top: 8px;margin-bottom: -8px;"><button class="btn btn-light border rounded-0" type="button" @click="calc()">Calculate</button></div>
                    </div>
                </div>
                <div class="col">
                    <div v-show="false" class="text-left" style="font-family: Arial; font-size: 10pt">
                      <p>Please fill out the Sales and Loan information.  Be as accurate as possible.</p>

                    </div>
                    <div>
                        <ul class="nav nav-tabs">
                            <li class="nav-item"><a class="nav-link active" role="tab" data-toggle="tab" href="#tab-1">Annual Details</a></li>
                            <li class="nav-item"><a class="nav-link" role="tab" data-toggle="tab" href="#tab-2">months Details</a></li>
                            <li class="nav-item"><a class="nav-link" role="tab" data-toggle="tab" href="#tab-3">Loan Summary</a></li>
                            <li v-show="false" class="nav-item"><a class="nav-link" role="tab" data-toggle="tab" href="#tab-4">Estimated Expenses</a></li>
                        </ul>
                        <div class="tab-content" style="margin-top: 30px">

                            <div v-show="years" class="tab-pane active" role="tabpanel" id="tab-1" style="font-family: Arial;font-size: 10pt;">
                                <div class="row" style="justify-content: center;">
                                    <div class="col" style="max-width: 700px; overflow:auto">
                                      <h6>Payment Schedule</h6><br>
                                      <div class="chart-wrapper">
                                        <canvas id="annualPaymentsChart" max-width="100" max-height="100"></canvas>
                                      </div>
                                      <p class="text-left" style="font-size: 8pt; font-style: italic; margin-left: 25px; margin-top: 10px">* End-of-Year Values</p>
                                    </div>                                   
                                </div>
                                <div class="row" style="margin-top: 25px;">
                                  <div class="col">
                                    <div class="table-responsive table-bordered" style="margin-top: 15px;">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr>
                                                    <th style="width: 15%;">Date</th>
                                                    <th style="width: 17%;">Payments</th>
                                                    <th style="width: 17%;">Principal Paid</th>
                                                    <th style="width: 17%;">Interest Paid</th>
                                                    <th style="width: 17%;">Total Interest</th>
                                                    <th style="width: 17%;">Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody class="border rounded-0">
                                                <tr v-for="item in years" v-bind:key="item.balance">
                                                    <td>{{item.date.toString()}}</td>
                                                    <td>{{currencyFormat(item.payment)}}</td>
                                                    <td>{{currencyFormat(item.principalPaid)}}</td>
                                                    <td>{{currencyFormat(item.interestPaid)}}</td>
                                                    <td>{{currencyFormat(item.totalInterest)}}</td>
                                                    <td>{{currencyFormat(item.balance)}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                  </div>
                                </div>
                            </div>

                            <div v-show="months" class="tab-pane" role="tabpanel" id="tab-2" style="font-family: Arial;font-size: 10pt;">

                                <div class="row" style="justify-content: center;">
                                    <div class="col" style="max-width: 700px; overflow:auto">
                                      <h6>Payment Schedule</h6><br>
                                      <div class="chart-wrapper">
                                        <canvas id="monthlyPaymentsChart" max-width="100" max-height="100"></canvas>
                                      </div>
                                      <p class="text-left" style="font-size: 8pt; font-style: italic; margin-left: 25px; margin-top: 10px">* End-of-Year Values</p>
                                    </div>                                   
                                </div>
                                <div class="row" style="margin-top: 25px;">
                                  <div class="col">


                                    <div class="table-responsive table-bordered" style="margin-top: 15px;">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr>
                                                    <th style="width: 15%;">Date</th>
                                                    <th style="width: 17%;">Payment</th>
                                                    <th style="width: 17%;">Principal Paid</th>
                                                    <th style="width: 17%;">Interest Paid</th>
                                                    <th style="width: 17%;">Total Interest</th>
                                                    <th style="width: 17%;">Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody class="border rounded-0">
                                                <tr v-for="(item) in months" v-bind:key="item.balance">
                                                    <td>{{dateFormat(item.date)}}</td>
                                                    <td>{{currencyFormat(item.payment)}}</td>
                                                    <td>{{currencyFormat(item.principalPaid)}}</td>
                                                    <td>{{currencyFormat(item.interestPaid)}}</td>
                                                    <td>{{currencyFormat(item.totalInterest)}}</td>
                                                    <td>{{currencyFormat(item.balance)}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                  </div>
                                </div>

                            </div>
                            <div class="tab-pane" role="tabpanel" id="tab-3" style="font-family: Arial;font-size: 10pt;">

                                <div class="row">
                                  <div class="col-5">
                                    <h6>Loan Details</h6><br>

                                    <div class="row" style="flex-direction: row">

                                    <div class="col" style="min-width: 100px">
                                      <p class="text-right">Loan Amount:</p>
                                      <p class="text-right">Rate:</p>
                                      <p class="text-right">Term:</p>
                                      <p class="text-right">Monthly Payment:</p>
                                      <p class="text-right">Total Interest Paid:</p>
                                    </div>
                                    <div class="col" style="margin-left: -25px; min-width: 100px">
                                      <p class="text-left">{{currencyFormat(loanAmount)}}</p>
                                      <p class="text-left">{{rate + "%"}}</p>
                                      <p class="text-left">{{term + " months"}}</p>
                                      <p class="text-left">{{currencyFormat(payment)}}</p>
                                      <p class="text-left">{{currencyFormat(totalInterest)}}</p>
                                    </div>   

                                    </div>

                                  </div> 

                                  <div class="col-7 text-center" style="max-width: 350px; overflow:auto">
                                    <h6>Interest Paid vs. Principal Paid</h6><br>
                                    <div class="chart-wrapper">
                                      <canvas id="totalInterestChart" max-width="100" max-height="100"></canvas>
                                    </div>
                                  </div>

                                </div>
                                <div class="row" style="margin-top: 25px;">
                                  <div class="col">
                                    <div class="table-responsive table-bordered" style="margin-top: 15px;">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr>
                                                    <th style="width: 15%;">Date</th>
                                                    <th style="width: 17%;">Payments</th>
                                                    <th style="width: 17%;">Principal Paid</th>
                                                    <th style="width: 17%;">Interest Paid</th>
                                                    <th style="width: 17%;">Total Interest</th>
                                                    <th style="width: 17%;">Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody class="border rounded-0">
                                                <tr v-for="item in years" v-bind:key="item.balance">
                                                    <td>{{item.date.toString()}}</td>
                                                    <td>{{currencyFormat(item.payment)}}</td>
                                                    <td>{{currencyFormat(item.principalPaid)}}</td>
                                                    <td>{{currencyFormat(item.interestPaid)}}</td>
                                                    <td>{{currencyFormat(item.totalInterest)}}</td>
                                                    <td>{{currencyFormat(item.balance)}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                  </div>
                                </div>

                            </div>
                            <div v-show="false" class="tab-pane" role="tabpanel" id="tab-4">
                                <p>Content for tab 4.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



  </section>

</template>

<script lang="js">
import DatePick from 'vue-date-pick';
import {DateTime} from 'luxon';
import 'vue-date-pick/dist/vueDatePick.css';
import Chart from 'chart.js';

  export default  {
    name: 'car-loan-calculator',
    props: [],
    // components: {DatePick},
    mounted: function () 
      {
        // eslint-disable-next-line no-undef
        $('#datepicker').datepicker({ uiLibrary: 'bootstrap4', value: this.startDate });

        this.calc();

        // eslint-disable-next-line no-undef
        $('#datepicker').datepicker({ uiLibrary: 'bootstrap4' });
      },
    data () {
      return {
        totalInterestChart: null,
        annualPaymentsChart: null,
        downPayment: '0',
        licensingFees: '0',
        loanAmount: '',
        months: null,
        monthlyInterestPaid: null,
        numFormat: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'symbol' }),
        owedOnTradein: '0',
        payment: 0,
        rate: '0',
        recalculated: false,
        salesTax: '0',
        startDate: DateTime.now().toFormat('LL/dd/yyyy'),
        term: '0',
        totalInterest: 0,
        tradeinValue: '0',
        vehiclePrice: '0',
        years: null,
      }
    },
    methods: {
      calc: function()
        {
          // eslint-disable-next-line no-undef
          this.startDate = $("#datepicker").val();
          this.calculateMonthlyValues();
          this.createTotalInterestChart();
          this.createAnnualPaymentsChart();
          this.createMonthlyPaymentsChart();
        },
      calculateMonthlyValues: function()
        {
          let frate                = parseFloat(this.rate)/(12*100);
          let inc                  = Math.pow((1+frate), parseFloat(this.term));

          this.payment             = Math.round((parseFloat(this.loanAmount)*frate*inc/(inc-1))*100)/100;
          this.months              = [];
          this.monthlyInterestPaid = [];

          let prevBal              = parseFloat(this.loanAmount);
          let totalInterest        = 0;

          let currentMonth         = (new DateTime(this.startDate)).plus({months: 1});
          let startYear            = currentMonth.year;
          let currentYear          = startYear;
          let years                = [];

          let yearDetails   = 
            { 
              date: currentYear,
              payment: 0,
              interestPaid: 0,
              principalPaid: 0,
              totalInterest: 0,
              balance: 0
            };

          for (let i=0; i<this.term; i++)
            {
              let interestPaid = prevBal * frate;
              let payment      = Math.min(this.payment, prevBal*(1+frate));
              let newBal       = prevBal - payment + interestPaid;

              if (i == this.term-1)
                { 
                  payment += newBal;
                  newBal = 0; 
                }

              totalInterest   += interestPaid;

              let month =
                {
                  date:          currentMonth,
                  payment:       payment,
                  interestPaid:  interestPaid,
                  principalPaid: payment - interestPaid,
                  totalInterest: totalInterest,
                  balance:       newBal
                };

              if (currentMonth.year == currentYear)
                {
                  yearDetails['payment']       += payment,7
                  yearDetails['interestPaid']  += interestPaid,
                  yearDetails['principalPaid'] += (payment - interestPaid),
                  yearDetails['totalInterest']  = totalInterest,
                  yearDetails['balance']        = newBal
                }
              else
                {
                  if (i<this.term-1)
                    { years.push(yearDetails); }
                  currentYear++;

                  yearDetails = 
                    { 
                      date: currentYear,
                      payment: payment,
                      interestPaid: interestPaid,
                      principalPaid: (payment - interestPaid),
                      totalInterest: totalInterest,
                      balance: newBal
                    };
                }

              currentMonth = currentMonth.plus({months: 1});
              prevBal = newBal;

              this.monthlyInterestPaid.push(interestPaid);
              this.months.push(month);
              this.totalInterest = totalInterest;
            }

          years.push(yearDetails);

          this.years = years;
        },
      createTotalInterestChart()
        {
          // eslint-disable-next-line no-unused-vars
          this.totalInterestChart = new Chart('totalInterestChart', 
            {
              type: 'pie', 
              responsive: true,
              maintainAspectRatio: false,
              data: 
                {
                  datasets: 
                    [
                      {
                        data: [Math.round(this.totalInterest*100)/100, this.loanAmount],
                        backgroundColor: ['#aaaaaa', '#6574de']
                      }
                    ],

                  // These labels appear in the legend and in the tooltips when hovering different arcs
                  labels: 
                    [
                      'Total Interest Paid',
                      'Principal Paid'
                    ]
                }
            });
        },
      createAnnualPaymentsChart()
        {
          let years         = [];
          let balance       = [];
          let interestPaid  = [];
          let principalPaid = [];

          for (let i=0; i<this.years.length; i++)
            {
              years.push(this.years[i].date.toString());
              balance.push(Math.round(this.years[i].balance*100)/100);
              interestPaid.push(Math.round(this.years[i].interestPaid*100)/100);
              principalPaid.push(Math.round(this.years[i].principalPaid*100)/100);
            }

          // eslint-disable-next-line no-unused-vars
          this.annualPaymentsChart = new Chart('annualPaymentsChart', 
            {
              type: 'bar',
              responsive: true,
              maintainAspectRatio: false,
              data: 
                {
                  labels: years,
                  datasets: 
                    [
                      {
                        label: 'Balance',
                        data: balance,
                        type: "line",
                        fill: false,
                        borderColor: '#aaaaaa',
                        borderWidth: 2
                      },
                      {
                        label: 'Interest Paid',
                        data: interestPaid,
                        backgroundColor: '#483D8B'
                      },
                      {
                        label: 'Principal Paid',
                        data: principalPaid,
                        backgroundColor: '#87cefa'
                      }
                    ]
                },
              options: 
                {
                  scales: 
                    {
                      xAxes: 
                        [
                          {
                            stacked: true
                          }
                        ],
                      yAxes: 
                        [
                          {
                            stacked: true,
                            display: true,
                            backgroundColor: '#888888',
                            type: 'linear'
                          }
                        ]
                    }
                }
            });
        },

      createMonthlyPaymentsChart()
        {
          let months        = [];
          let balance       = [];
          let interestPaid  = [];
          let principalPaid = [];

          for (let i=0; i<this.months.length; i++)
            {
              months.push(this.months[i].date.toFormat('LL/YY'));
              balance.push(Math.round(this.months[i].balance*100)/100);
              interestPaid.push(Math.round(this.months[i].interestPaid*100)/100);
              principalPaid.push(Math.round(this.months[i].principalPaid*100)/100);
            }

          // eslint-disable-next-line no-unused-vars
          this.monthlyPayments = new Chart('monthlyPaymentsChart', 
            {
              type: 'bar',
              responsive: true,
              maintainAspectRatio: false,
              data: 
                {
                  labels: months,
                  datasets: 
                    [
                      {
                        label: 'Balance',
                        data: balance,
                        type: 'line',
                        fill: false,
                        borderColor: '#aaaaaa',
                        borderWidth: 2,
                        yAxisID: 'y-bal'
                      },
                      {
                        label: 'Interest Paid',
                        data: interestPaid,
                        backgroundColor: '#483D8B',
                        yAxisID: 'y-payments'
                      },
                      {
                        label: 'Principal Paid',
                        data: principalPaid,
                        backgroundColor: '#87cefa',
                        yAxisID: 'y-payments'
                      }
                    ]
                },
              options: 
                {
                  scales: 
                    {
                      xAxes: 
                        [
                          {
                            stacked: true
                          }],
                      yAxes: 
                        [
                          {
                            id: 'y-bal',
                            label: 'Balance',
                            stacked: true,
                            display: true,
                            backgroundColor: '#888888',
                            type: 'linear',
                            position: 'left',
                            scaleLabel: 
                              {
                                display: true,
                                labelString: 'Balance'
                              }
                          },
                          {
                            id: 'y-payments',
                            label: 'Interest and Principal Paid',
                            stacked: true,
                            display: true,
                            backgroundColor: '#888888',
                            type: 'linear',
                            position: 'right',
                            scaleLabel: 
                              {
                                display: true,
                                labelString: 'Interest and Principal Paid'
                              }
                          }
                        ]
                    }
                }
            });
        },
      currencyFormat: function(val)
        { 
          let temp = this.numFormat.format(val);
          return(temp); 
        },
      dateFormat(val)
        { return(val.toFormat('LL/dd/yyyy')); },
      recalc()
        {
          this.calc();
          this.recalculated = true;
        },
      setDate(val)
        {
          //console.log(val);
        },
      updateTotal: function()
        {
          this.loanAmount = 
            parseFloat(this.vehiclePrice) -
            parseFloat(this.downPayment) -
            parseFloat(this.tradeinValue) +
            parseFloat(this.owedOnTradein) +
            parseFloat(this.licensingFees) +
            (parseFloat(this.vehiclePrice) * (parseFloat(this.salesTax)/100));
        }
    },
    computed: {

      },
    watch:
      {
        downPayment: function(val)
          { 
            if (!val)
              { this.downPayment = 0; }
            else if (this.downPayment[0] == '0')
              { this.downPayment = this.downPayment.slice(1); }

            this.updateTotal(); 
          },
        licensingFees: function(val)
          { 
            if (!val)
              { this.licensingFees = 0; }
            else if (this.licensingFees[0] == '0')
              { this.licensingFees = this.licensingFees.slice(1); }
              
            this.updateTotal(); 
          },
        owedOnTradein: function(val)
          { 
            if (!val)
              { this.owedOnTradein = 0; }
            else if (this.owedOnTradein[0] == '0')
              { this.owedOnTradein = this.owedOnTradein.slice(1); }
              
            this.updateTotal(); 
          },
        rate: function(val)
          { 
            if (!val)
              { this.rate = 0; }
            else if (this.rate[0] == '0')
              { this.rate = this.rate.slice(1); }
              
            this.updateTotal(); 
          },
        salesTax: function(val)
          { 
            if (!val)
              { this.salesTax = 0; }
            else if (this.salesTax[0] == '0')
              { this.salesTax = this.salesTax.slice(1); }
              
            this.updateTotal(); 
          },
        term: function(val)
          { 
            if (!val)
              { this.term = 0; }
            else if (this.term[0] == '0')
              { this.term = this.term.slice(1); }
              
            this.updateTotal(); 
          },
        tradeinValue: function(val)
          { 
            if (!val)
              { this.tradeinValue = 0; }
            else if (this.tradeinValue[0] == '0')
              { this.tradeinValue = this.tradeinValue.slice(1); }
              
           this.updateTotal(); 
          },
        vehiclePrice: function(val)
          { 
            if (!val)
              { this.vehiclePrice = 0; }
            else if (this.vehiclePrice[0] == '0')
              { this.vehiclePrice = this.vehiclePrice.slice(1); }
              
            this.updateTotal(); 
          }
      }
}


</script>

<style scoped lang="scss">
  .car-loan {

  }
  .chart-wrapper
    {
      position: relative;
      width: 100%;
    }
</style>

<style>
  i.gj-icon
  {
    margin-top: -4px !important;
  }

  .icon-wrapper {
    margin-top: 10px;
  }

</style>
