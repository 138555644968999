export const config =
  {
    api:
      {
        development:
          {
            uri: 'http://localhost:3030'
          },
        production:
          {
            uri: 'http://lookoutplanner.com:3030'
          }
      }
  }