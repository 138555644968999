<template lang="html">

  <section class="login-register">

    <div ref="loginRegisterModal" class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content wrapper" :class="{active: wrapperActive, 'active-popup': wrapperActivePopup}">
          <span @click="closePopup" class="icon-close"><ion-icon name="close"></ion-icon></span>
          <div class="form-box login">
            <h2>Login</h2>
            <form>
              <div class="input-box">
                <input name="username" type="text" v-model="form.login.username" required>
                <span class="icon"><ion-icon name="person"></ion-icon></span>
                <label>Username</label>
              </div>
              <div class="input-box">
                <input name="password" type="password" v-model="loginPassword" required>
                <span class="icon"><ion-icon name="lock-closed"></ion-icon></span>
                <label>Password</label>
              </div>
              <div class="remember-forget">
                <label><input name="rememberme" type="checkbox" v-model="form.login.rememberMe">Remember me</label>
                <a @click="forgotPassword">Forgot Password?</a>
              </div>
              <button class="btn" type="submit" @click="login">Login</button>
              <div class="login-register">
                <p>Don't have an account? &nbsp;<a href="#" @click="showRegisterForm" class="register-link">Register</a></p>
              </div>
            </form>
          </div>
          <div class="form-box register">
            <h2>Registration</h2>
            <form>
              <div class="input-box">
                <input name="username" type="text" v-model="form.register.username" required>
                <span class="icon"><ion-icon name="person"></ion-icon></span>
                <label>Username</label>
              </div>
              <div class="input-box">
                <input name="email" type="email" v-model="form.register.email" required>
                <span class="icon"><ion-icon name="mail"></ion-icon></span>
                <label>Email</label>
              </div>
              <div class="input-box">
                <input name="password1" type="password" v-model="registerPassword1" required>
                <span class="icon"><ion-icon name="lock-closed"></ion-icon></span>
                <label>Password</label>
              </div>
              <div class="input-box">
                <input name="password2" type="password" v-model="registerPassword2" required>
                <span class="icon"><ion-icon name="lock-closed"></ion-icon></span>
                <label>Confirm Password</label>
              </div>
              <div class="remember-forget">
                <label><input name="agree" type="checkbox">I agree to the terms and conditions</label>
              </div>
              <button class="btn" @click="register">Register</button>
              <div class="login-register">
                <p>Already have an account? &nbsp;<a href="#" @click="showLoginForm" cclass="login-link">Login</a></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script lang="js">

  import $                                          from 'jquery';
  import { mapActions, mapState, mapWritableState } from 'pinia';
  import {Modal}                                    from 'bootstrap';
  import sha256                                     from 'crypto-js/sha256';
  import {userStore}                                from '../stores/userStore.js';

  export default  {
    name: 'login-register',
    props: [],
    mounted () {
      this.loginModal = new Modal('#loginModal'); 
      //this.loginModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('#loginModal')) // Returns a Bootstrap modal instance
      
    },
    data () 
      {
        return {
          form:
            {
              login:
                {
                  passwordHash: null,
                  rememberMe: false,
                  username: null,
                },
              register:
                {
                  email: null,
                  passwordHash: null,
                  username: null
                }
            },
          loginModal: null,
          loginPassword: null,
          registerPassword1: null,
          registerPassword2: null,
          loginActive: true,
          registerActive: false,
          wrapperActive: false,
          wrapperActivePopup: false
        }
      },
    methods: 
      {
        login()
          {
            const user = userStore();
            user.login(this.form.login);
          },
        register()
          {
            const user = userStore();
            user.register(this.form.register);
          },
        showLoginForm()
          { this.wrapperActive = false; },
        showRegisterForm()
          { this.wrapperActive = true; },
        closePopup()
          { this.loginModal.hide(); },
        show()
          { this.loginModal.show(); },
        getPasswordHash: function(password)
          { return(sha256(password).toString()); },
        ...mapActions(userStore, ['forgotPassword', 'login', 'register']),
      },
    computed:
      {
        // ...mapWritableState(userStore, { useCookies: 'useCookies' }),
        // ...mapWritableState(userStore, { username: 'username' })
      },
    watch:
      {
        useCookies()
          {

          },
        loginPassword()
          { this.form.login.passwordHash = this.getPasswordHash(this.loginPassword); },
        registerPassword1() 
          { 
            if (this.registerPassword1 == this.registerPassword2) 
              { this.form.register.passwordHash = this.getPasswordHash(this.registerPassword1); }
          },
        registerPassword2() 
          { 
            if (this.registerPassword1 == this.registerPassword2) 
              { this.form.register.passwordHash = this.getPasswordHash(this.registerPassword1); }
          }
      }
}


</script>

<style scoped lang="scss">
* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  background: url('../../public/confirm-email/assets/img/prairie-storm.png') no-repeat;
  background-size: cover;
  background-position: center;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
}

.logo {
  font-size: 2em;
  color: #fff;
  user-select: none;
}

.navigation a {
  position: relative;
  font-size: 1.1em;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  margin-left: 40px;
  transition: .5s;
}

.navigation a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -6px;
  width: 100%;
  height: 3px;
  background: #fff;
  border-radius: 5px;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .5s;
}

.navigation a:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}

.navigation .btnLogin-popup {
  width: 130px;
  height: 50px;
  background: transparent;
  border: 2px solid #fff;
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.1em;
  color: #fff;
  font-weight: 500;
  margin-left: 40px;
}

.navigation .btnLogin-popup:hover {
  background: #fff;
  color: #162938;
}

.wrapper {
  position: relative;
  width: 400px;
  height: 440px;
  // background: transparent;
  background: white;
  border: 2px solid rgba(255,255,255,.5);
  border-radius: 20px;
  backdrop-filter: blur(20px);
  box-shadow: 0 0 30px rgba(0,0,0,.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // transform: scale(0);
  transition: transform .5s ease, height .2s ease;
  margin-top: 200px;
}

.modal-dialog {
  //align-items: center;
  display: flex;
  height: 100%;

}

.modal-content {
  margin-left: 0;
  margin-right: 0;
  flex-direction: row;
  justify-content: center;
}

.wrapper.active-popup {
  transform: scale(1);
}

.wrapper.active {
  height: 600px;
}

.wrapper .form-box {
  width: 100%;
  padding: 40px;
}

.wrapper .form-box.login {
  transition: transform .18s ease;
  transform: translateX(0);
}

.wrapper.active .form-box.login {
  transition: none;
  transform: translateX(-400px);
}

.wrapper .form-box.register {
  position: absolute;
  transition: none;
  transform: translateX(400px);
}

.wrapper.active .form-box.register {
  transition: transform .18s ease;
  transform: translateX(0);
}

.wrapper .icon-close {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 45px;
  height: 45px;
  background: #162938;
  font-size: 1em;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items:center;
  border-bottom-left-radius: 20px;
  cursor: pointer;
  z-index: 1; 
}
.form-box h2 {
  font-size: 2em;
  color: #162938;
  text-align: center;
}

.input-box {
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #162938;
  margin: 30px 0;
}

.input-box label {
  position: absolute;
  top: 50%;
  left: 35px;
  transform: translateY(-50%);
  font-size: 1em;
  color: #162938;
  font-weight: 500;
  pointer-events: none;
  transition: .5s;
}

.input-box input:focus~label,
.input-box input:valid~label {
  top: -2px;
}

.input-box input:focus~span,
.input-box input:valid~span {
  top: -1px;
}

.input-box input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1em;
  color: #162938;
  font-weight: 600;
  padding: 0 35px 0 5px;
}

.input-box .icon {
  position: absolute;
  left: 8px;
  font-size: 1.2em;
  color: #162938;
  line-height: 57px;
  top: 50%;
  transform: translateY(-50%);
  transition: .5s;
}

.remember-forget {
  font-size: .9em;
  color: #162938;
  font-weight: 500;
  margin: -15px 0 15px;
  display: flex;
  justify-content: space-between;
}

.remember-forget label input {
  accent-color: #162938;
  margin-right: 8px;
}

.remember-forget a {
  color: #162938;
  text-decoration: none;
}

.remember-forget a:hover {
  text-decoration: underline;
}

.btn {
  width: 100%;
  height: 35px;
  background: #28445a;
  border: none;
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
  color: #fff;
  font-weight: 200;
}

.login-register {
  font-size: .9em;
  color: #162938;
  text-align: center;
  font-weight: 500;
  //margin: 25px 0 10px;
}

.login-register p a {
  color: #162938;
  text-decoration: none;
  font-weight: 600;
}

.login-register p a:hover {
  text-decoration: underline;
}

.modal-dialog { 
    display: inline-block;  
    text-align: left;   
    vertical-align: middle;
}
</style>
