<template lang="html">

  <section class="mortgage-calculator">

    <div class="article-list">
        <div class="container" id="main-container"><img class="img-fluid shadow" id="lookout-header" src="/img/mortgage1.jpg" width="1110" height="460" style="height: 300px;">
            <div class="row justify-content-center articles" style="margin-top: 15px;font-family: 'Alegreya Sans SC', sans-serif; height: 70px;">
                <div class="col">
                    <ol class="breadcrumb" style="background-color: rgb(255,255,255);font-family: Actor, sans-serif;font-size: 12pt;color: rgb(55,59,62);">
                        <li class="breadcrumb-item"><a href="#/"><span style="color: rgb(118,119,119);">Home</span></a></li>
                        <li class="breadcrumb-item"><a href="#"><span style="color: rgb(118,119,119);">Mortgage Calculator</span></a></li>
                    </ol>
                </div>
            </div>
            <div class="row" style="margin-top: -20px;">
                <div class="col">
                    <div class="btn-toolbar bg-light d-flex justify-content-between">
                        <div class="btn-group" role="group">
                          <button @click="deleteSelected()" class="btn btn-light" type="button" style=";margin-left: 23px;">
                          <i class="fas fa-trash-alt" data-toggle="tooltip" data-bs-tooltip="" title="Delete"></i></button>
                        </div>
                        <div class="btn-group" role="group">
                          <button @click="addMortgage()" class="btn btn-light" type="button" style="padding-right: 12px;padding-left: 12px;">
                            <i class="fa fa-plus" data-toggle="tooltip" data-bs-tooltip="" title="Add"></i>
                          </button>
                          <button @click="saveMortgages()" class="btn btn-light" type="button" style="padding-right: 12px;padding-left: 12px;">
                            <i class="fa fa-save" data-toggle="tooltip" data-bs-tooltip="" title="Save"></i>
                          </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="table-responsive" style="margin-bottom: -13px;">
                        <table class="table" id="mortgage-table">
                            <thead style="font-size: 10pt;">
                                <tr>
                                    <th class="text-left" style="width: 8%;">
                                        <div class="form-check text-center"><input @click="toggleSelectAll($event)" class="form-check-input" type="checkbox" id="formCheck-3" style="width: 14px;height: 14px;">
                                          <label class="form-check-label" for="formCheck-3"></label>
                                        </div>
                                    </th>
                                    <th style="text-align: center;width: 7%;">Primary</th>
                                    <th class="text-left" style="width: 20%;">Name</th>
                                    <th class="text-left" style="width: 25%;">Mortgage Balance</th>
                                    <th class="text-left">Mortgage Rate</th>
                                    <th class="text-left">Mortgage Term</th>
                                </tr>
                            </thead>
                            <tbody>

                              <!-- <template> -->

                                <template v-for="(mortgage, index) of mortgages" :key="'mortgage-'+index">

                                  <tr>
                                    <td>
                                        <div class="d-flex flex-row align-items-center flex-nowrap" style="margin-top: 10px;">
                                          <i v-if="mortgage.primary && !mortgage.expanded" @click="toggleDetails(mortgage)" class="fas fa-caret-right" style="margin-right: 10px"></i>
                                          <i v-else-if="mortgage.primary && mortgage.expanded" @click="toggleDetails(mortgage)" class="fas fa-sort-down" style="margin-right: 10px; opacity: 1"></i>
                                          <i v-else-if="!mortgage.primary" class="fas fa-caret-right" style="margin-right: 10px; opacity: 0.33"></i>
                                          <i v-if="mortgage.show" @click="hideMortgage(index)" class="fa fa-eye card-icon" style="margin-right: 10px;"></i>
                                          <i v-else @click="showMortgage(index)" class="fa fa-eye-slash card-icon" style="margin-right: 10px;"></i>
                                          <input v-model="mortgage.selected" type="checkbox" style="margin-right: 10px; width: 14px; height: 14px;">
                                          <i @click="copyMortgage(index)" class="fa fa-copy card-icon" style="margin-right: 10px;"></i>
                                          <i @click="deleteMortgage(index)" class="fa fa-trash card-icon"></i>
                                        </div>
                                    </td>
                                    <td style="text-align: center;min-width: 50px;max-width: 50px;">
                                      <input v-model="mortgage.primary" @click="togglePrimary(mortgage)" type="checkbox" style="margin-top: 10px;">
                                    </td>
                                    <td>
                                        <div class="input-group input-group-sm">
                                            <div class="input-group-prepend"></div><input v-model="mortgage.name" class="form-control" type="text">
                                            <div class="input-group-append"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group input-group-sm d-flex flex-nowrap">
                                            <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="balance" v-model="mortgage.balance" class="form-control" type="text" style="width: 100%;">
                                            <div class="input-group-append"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group input-group-sm d-flex flex-nowrap">
                                            <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="rate" v-model="mortgage.rate" class="form-control" type="text">
                                            <div class="input-group-append"><span class="input-group-text">%/yr</span></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group input-group-sm">
                                            <div class="input-group-prepend"></div><input v-validate="'numeric'" name="term" v-model="mortgage.term" class="form-control" type="text">
                                            <!-- <div v-if="mortgage.termUnit == 'month'" class="input-group-append">
                                              <span class="input-group-text" @click="setTermUnit(mortgage, 'month')" style="background: rgb(196,200,203); cursor:pointer">mo</span>
                                              <span class="input-group-text" @click="setTermUnit(mortgage, 'year')" style="cursor:pointer">yr</span>
                                            </div>
                                            <div v-else class="input-group-append">
                                              <span class="input-group-text" @click="setTermUnit(mortgage, 'month')" style="cursor:pointer">mo</span>
                                              <span class="input-group-text" @click="setTermUnit(mortgage, 'year')"  style="background: rgb(196,200,203); cursor:pointer">yr</span>
                                            </div> -->
                                            <div class="input-group-append">
                                              <span class="input-group-text" @click="setTermUnit(mortgage, 'month')" style="cursor:pointer">mo</span>
                                            </div>
                                        </div>
                                    </td>
                                  </tr>

                                  <tr v-if="mortgage.primary && mortgage.expanded" :key="mortgage.id">
                                    <td colspan="6">
                                        <div style="margin-top: 10px;margin-left: 40px;margin-right: 40px;margin-bottom: 0px;">
                                            <ul class="nav nav-tabs" role="tablist">
                                                <li class="nav-item" role="presentation"><a class="nav-link active" role="tab" data-toggle="tab" href="#tab-1">Asset Information</a></li>
                                                <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-toggle="tab" href="#tab-2">Home Expenses</a></li>
                                                <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-toggle="tab" href="#tab-3">Rental Information</a></li>
                                            </ul>
                                            <div class="tab-content">
                                                <div class="tab-pane active" role="tabpanel" id="tab-1">


                                                    <div class="row" style="margin-top: 20px">

                                                      <div class="col"  style="max-width: 40%">

                                                        <div class="detail-table">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style="min-width: 110px;border-style: none;"></th>
                                                                    <th style="max-width: 140px;min-width: 140px;border-style: none;">Amount</th>
                                                                    <th style="min-width: 95px;border-style: none;">% Increase</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="text-right">Purchase Price:</td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="purchasePrice" v-model="mortgage.asset.purchasePrice" class="form-control" type="text" style="min-width: 90px;max-width: 90px;">
                                                                            <div class="input-group-append"></div>
                                                                        </div>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-right">Closing Date:</td>
                                                                    <td class="text-left" >
                                                                        <!-- <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"></div><input v-model="mortgage.asset.closingDate" class="form-control" type="text" style="min-width: 115px;max-width: 115px;">
                                                                            <div class="input-group-append"></div>
                                                                        </div> -->
                                                                        <!-- <date-pick v-model="mortgage.asset.closingDate"></date-pick> -->
                                                                        <VueDatePicker v-model="mortgage.asset.closingDate" format="MM/DD/YYYY" style="min-width: 116px; max-width: 116px;"></VueDatePicker>
                                                                        <!-- <input id="datepicker" width="160" style="border-style: solid; border-color: black; font-size: 10pt; height: 30px"/> -->
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-right">Down Payment:</td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="downPayment" v-model="mortgage.asset.downPayment" class="form-control" type="text" style="min-width: 90px;max-width: 90px;">
                                                                            <div class="input-group-append"></div>
                                                                        </div>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-right">Current Value:</td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="currentValue" v-model="mortgage.asset.currentValue" class="form-control" type="text" style="min-width: 90px;max-width: 90px;">
                                                                            <div class="input-group-append"></div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"></div><input v-validate="'numeric'" name="annualCurrentValuePctIncrease" v-model="mortgage.asset.annualCurrentValuePctIncrease" class="form-control" type="text" style="max-width: 50px;">
                                                                            <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        </div>

                                                      </div>
                                                      <div class="col text-left"  style="max-width: 60%; margin-top: -28px">
                                                        <h2 style="margin-left: -15px;">Instructions</h2>
                                                        <p style="margin-top: -30px"><ol style="list-style-type: square;"><li>Please provide a Closing Date so that the PMI expense can be more accurately estimated.</li></ol></p>
                                                      </div>                                                     

                                                    </div>





                                                </div>
                                                <div class="tab-pane" role="tabpanel" id="tab-2">

                                                  <div class="row"  style="margin-top: 20px">
                                                    <div class="col" style="max-width: 40%">

                                                      <div class="detail-table">
                                                          <table>
                                                              <thead>
                                                                  <tr>
                                                                      <th style="border-style: none;"></th>
                                                                      <th style="max-width: 90px;text-align: center;min-width: -9px89px;border-style: none;">Amount</th>
                                                                      <th style="border-style: none;">% Increase</th>
                                                                  </tr>
                                                              </thead>
                                                              <tbody>
                                                                  <tr>
                                                                      <td class="text-right">Taxes:</td>
                                                                      <td>
                                                                          <div class="input-group input-group-sm">
                                                                              <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="annualTaxes" v-model="mortgage.expenses.annualTaxes" class="form-control" type="text" style="max-width: 90px;min-width: 90px;">
                                                                              <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                          </div>
                                                                      </td>
                                                                      <td>
                                                                          <div class="input-group input-group-sm">
                                                                              <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="annualTaxesIncrease" v-model="mortgage.expenses.annualTaxesIncrease" class="form-control" type="text" style="max-width: 60px;">
                                                                              <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                          </div>
                                                                      </td>
                                                                  </tr>
                                                                  <tr>
                                                                      <td class="text-right">Insurance:</td>
                                                                      <td>
                                                                          <div class="input-group input-group-sm">
                                                                              <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="annualInsurance" v-model="mortgage.expenses.annualInsurance" class="form-control" type="text" style="max-width: 90px;min-width: 90px;">
                                                                              <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                          </div>
                                                                      </td>
                                                                      <td>
                                                                          <div class="input-group input-group-sm">
                                                                              <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="annualInsuranceIncrease" v-model="mortgage.expenses.annualInsuranceIncrease" class="form-control" type="text" style="max-width: 60px;">
                                                                              <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                          </div>
                                                                      </td>
                                                                  </tr>
                                                                  <tr>
                                                                      <td class="text-right">PMI:</td>
                                                                      <td>
                                                                          <div class="input-group input-group-sm">
                                                                              <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="pmi" v-model="mortgage.expenses.pmi" class="form-control" type="text" style="max-width: 120px;min-width: 120px;">
                                                                              <div class="input-group-append"><span class="input-group-text">%</span></div>
                                                                          </div>

                                                                      </td>
                                                                      <td></td>
                                                                  </tr>
                                                                  <tr>
                                                                      <td class="text-right">HOA Fees:</td>
                                                                      <td>
                                                                          <div class="input-group input-group-sm">
                                                                              <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="monthlyHOAFees" v-model="mortgage.expenses.monthlyHOAFees" class="form-control" type="text" style="max-width: 90px;min-width: 90px;">
                                                                              <div class="input-group-append"><span class="input-group-text">mo</span></div>
                                                                          </div>
                                                                      </td>
                                                                      <td>
                                                                          <div class="input-group input-group-sm">
                                                                              <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="annualHOAFeesIncrease" v-model="mortgage.expenses.annualHOAFeesIncrease" class="form-control" type="text" style="max-width: 60px;">
                                                                              <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                          </div>
                                                                      </td>
                                                                  </tr>
                                                                  <tr>
                                                                      <td class="text-right">Maintenance:</td>
                                                                      <td>
                                                                          <div class="input-group input-group-sm">
                                                                              <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="annualMaintenance" v-model="mortgage.expenses.annualMaintenance" class="form-control" type="text" style="max-width: 90px;min-width: 90px;">
                                                                              <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                          </div>
                                                                      </td>
                                                                      <td>
                                                                          <div class="input-group input-group-sm">
                                                                              <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="annualMaintenanceIncrease" v-model="mortgage.expenses.annualMaintenanceIncrease" class="form-control" type="text" style="max-width: 60px;">
                                                                              <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                          </div>
                                                                      </td>
                                                                  </tr>
                                                                  <tr>
                                                                      <td class="text-right">Misc Fees:</td>
                                                                      <td>
                                                                          <div class="input-group input-group-sm">
                                                                              <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="annualMiscFees" v-model="mortgage.expenses.annualMiscFees" class="form-control" type="text" style="max-width: 90px;min-width: 90px;">
                                                                              <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                          </div>
                                                                      </td>
                                                                      <td>
                                                                          <div class="input-group input-group-sm">
                                                                              <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="annualMiscFeesIncrease" v-model="mortgage.expenses.annualMiscFeesIncrease" class="form-control" type="text" style="max-width: 60px;">
                                                                              <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                          </div>
                                                                      </td>
                                                                  </tr>
                                                              </tbody>
                                                          </table>
                                                      </div>

                                                    </div>
                                                    <div class="col text-left"  style="max-width: 60%; margin-top: -28px">
                                                        <h2>Events</h2>
                                                        <p style="margin-top: -30px">- Please provide a Closing Date so that the PMI expense can be more accurately estimated.</p>
                                                      </div>  
                                                  </div>


                                                </div>
                                                <div class="tab-pane" role="tabpanel" id="tab-3">

                                                  <div class="row" style="margin-top: 20px">

                                                    <div class="detail-table">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style="border-style: none;"></th>
                                                                    <th style="max-width: 80px;text-align: center;border-style: none;">Amount</th>
                                                                    <th style="border-style: none;">% Increase</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="text-right">Rent:</td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="monthlyRent" v-model="mortgage.rent.monthlyRent" class="form-control" type="text" placeholder="10000" style="min-width: 80px;max-width: 80px;">
                                                                            <div class="input-group-append"><span class="input-group-text">mo</span></div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="annualRentIncrease" v-model="mortgage.rent.annualRentIncrease" class="form-control" type="text" style="max-width: 60px;" placeholder="8.29">
                                                                            <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-right">Vacancy Rate:</td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"></div><input v-validate="'numeric'" name="vacancyRate" v-model="mortgage.rent.vacancyRate" class="form-control" type="text" placeholder="10" style="min-width: 115px;max-width: 115px;">
                                                                            <div class="input-group-append"><span class="input-group-text">%</span></div>
                                                                        </div>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-right">Annual Management Fee:</td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="annualManagementFee" v-model="mortgage.rent.annualManagementFee" class="form-control" type="text" placeholder="100" style="max-width: 90px;min-width: 90px;">
                                                                            <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="annualManagementFeeIncrease" v-model="mortgage.rent.annualManagementFeeIncrease" class="form-control" type="text" style="max-width: 60px;">
                                                                            <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-right">Monthly Management Fee:</td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"></div><input v-validate="'numeric'" name="monthlyManagementFee" v-model="mortgage.rent.monthlyManagementFee" class="form-control" type="text" placeholder="3" style="max-width: 90px;min-width: 90px;">
                                                                            <div class="input-group-append"><span class="input-group-text">%/mo</span></div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="monthlyManagementFeeIncrease" v-model="mortgage.rent.monthlyManagementFeeIncrease" class="form-control" type="text" style="max-width: 60px;">
                                                                            <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-right">Maintenance:</td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="annualMaintenance" v-model="mortgage.rent.annualMaintenance" class="form-control" type="text" placeholder="1200" style="max-width: 90px;min-width: 90px;">
                                                                            <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="annualMaintenanceIncrease" v-model="mortgage.rent.annualMaintenanceIncrease" class="form-control" type="text" style="max-width: 60px;">
                                                                            <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-right">Misc Costs:</td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"><span class="input-group-text">$</span></div><input v-validate="'numeric'" name="annualMiscCosts" v-model="mortgage.rent.annualMiscCosts" class="form-control" type="text" placeholder="1000" style="max-width: 90px;min-width: 90px;">
                                                                            <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="input-group input-group-sm">
                                                                            <div class="input-group-prepend"></div><input v-validate="'decimal:3'" name="annualMiscCostsIncrease" v-model="mortgage.rent.annualMiscCostsIncrease" class="form-control" type="text" style="max-width: 60px;">
                                                                            <div class="input-group-append"><span class="input-group-text">yr</span></div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                  </div>

                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                  </tr>

                                </template>

                              <!-- </template> -->

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 30px;">
                <div v-show="mortgages.length > 0" class="col text-right"><button @click="calc()" class="btn btn-light btn-sm" type="button">Update</button></div>
            </div> 
            <div class="row" style="margin-bottom: 20px; margin-top: 10px;">
                <div class="col">
                    <hr>
                </div>
            </div>
            <div v-show="showTabs" class="row justify-content-center articles" style="font-family: 'Alegreya Sans SC', sans-serif;">
                <div class="col">
                    <div>
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" role="presentation"><a class="nav-link active" role="tab" data-toggle="tab" href="#tab-4">Mortgage Details</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-toggle="tab" href="#tab-5">Equity, Income & Expenses</a></li>
                            <!-- <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-toggle="tab" href="#tab-6">Summary</a></li> -->
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active" role="tabpanel" id="tab-4">

                                <div class="row" style="margin-top: 10px;">

                                    <div class="col" style="max-width: 100%; overflow:auto; margin-top: 10px;">
                                      <!-- <h6>Payment Schedule</h6><br> -->
                                      <div class="chart-wrapper">
                                        <canvas id="actualPaymentsChart" max-width="100" max-height="100"></canvas>
                                      </div>
                                      <div>
                                        <p class="text-left" style="font-size: 8pt; font-style: italic; margin-left: 25px; margin-top: 10px">* End-of-Year Values</p>
                                      </div>                                   
                                    </div>

                                </div>

                                <div class="row" style="margin-top: 20px;">
                                  <div class="col">
                                    <div class="table-responsive table-bordered" style="margin-top: 15px;">
                                      <table class="table table-bordered table-sm">
                                          <thead>
                                              <tr>
                                                  <th style="width: 15%;">Date</th>
                                                  <th style="width: 17%;">Payment</th>
                                                  <th style="width: 17%;">Principal Paid</th>
                                                  <th style="width: 17%;">Interest Paid</th>
                                                  <th style="width: 17%;">Total Interest</th>
                                                  <th style="width: 17%;">Balance</th>
                                              </tr>
                                          </thead>
                                          <tbody v-if="chartData && chartData['act']" class="border rounded-0">
                                              <tr v-for="(date, index) in chartData['act'].date" :key="date.toString()">
                                                  <td>{{dateFormat(date)}}</td>
                                                  <td>{{currencyFormat(chartData['act'].payment[index])}}</td>
                                                  <td>{{currencyFormat(chartData['act'].principalPaid[index])}}</td>
                                                  <td>{{currencyFormat(chartData['act'].interestPaid[index])}}</td>
                                                  <td>{{currencyFormat(chartData['act'].totalInterest[index])}}</td>
                                                  <td>{{currencyFormat(chartData['act'].balance[index])}}</td>
                                              </tr>
                                          </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>                           

                            </div>
                            <div class="tab-pane" role="tabpanel" id="tab-5">

                                <div class="row" style="margin-top: 10px;">

                                  <div class="col" style="max-width: 100%; overflow:auto; margin-top: 10px;">
                                    <!-- <h6>Equity, Income & Expenses</h6><br> -->
                                    <div class="chart-wrapper">
                                      <canvas id="equityAndCashflowChart" max-width="100" max-height="100"></canvas>
                                    </div>
                                    <div>
                                      <p class="text-left" style="font-size: 8pt; font-style: italic; margin-left: 25px; margin-top: 10px">* End-of-Year Values</p>
                                    </div>                                   
                                  </div>

                                </div>

                                <div class="row" style="margin-top: 20px;">
                                  <div class="col">
                                    <div class="table-responsive table-bordered" style="margin-top: 15px;">
                                      <table class="table table-bordered table-sm">
                                        <thead>
                                          <tr>
                                              <th style="width: 10%;">Date</th>
                                              <th style="width: 15%;">Equity</th>
                                              <th style="width: 15%;">Expenses</th>
                                              <th style="width: 15%;">Income</th>
                                              <th style="width: 45%;">Events</th>
                                          </tr>
                                        </thead>
                                        <tbody v-if="chartData && chartData['act']" class="border rounded-0">
                                          <tr v-for="(date, index) in chartData['act'].date" :key="date.toString()">
                                              <td>{{dateFormat(date)}}</td>
                                              <td>{{currencyFormat(chartData['act'].equity[index])}}</td>
                                              <td>{{currencyFormat(chartData['act'].expenses[index]*-1)}}</td>
                                              <td>{{currencyFormat(chartData['act'].income[index])}}</td>
                                              <td class="text-left">
                                                <ol v-if="chartData['act'].events[index] != null">
                                                  <li style="list-style-type: square; margin-left: 20px" v-for="(event, idx) in chartData['act'].events[index]" :key="'event-'+idx">{{event}}</li>
                                                </ol>
                                              </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>                           

                            </div>
                            <!-- <div class="tab-pane" role="tabpanel" id="tab-6">
                              <p>Content for tab 3.</p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
    <div class="footer-dark">
        <footer style="height: 100%;"></footer>
    </div>

  </section>

</template>

<script lang="js">
//import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Chart from 'chart.js';

const {DateTime} = require('luxon');

export default  {
    name: 'mortgage-calculator',
    props: [],
    components: 
      {
        //VueDatePicker
      },
    mounted: function () 
      {
        // eslint-disable-next-line no-undef
        //$('#datepicker').datepicker({ uiLibrary: 'bootstrap4', value: this.startDate });

        this.calc();

        // eslint-disable-next-line no-undef
        //$('#datepicker').datepicker({ uiLibrary: 'bootstrap4' });
      },
    computed:
      {
        showTabs: function()
          { return((this.calculated && this.chartData['act'] && this.chartData['act']['date'] && this.chartData['act']['date'].length > 0)?true:false); }
      },
    created ()
      { this.addMortgage(); },
    data () 
      {
        return {
          actualPaymentsChart: null,
          chartData: null,
          calculated: false,
          equityAndCashflowChart: null,
          evenlyDistributeExpenses: true,
          initialBalance: 0,
          mortgages: [],
          numFormat: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'symbol' }),
          payment: 0,
          term: 0,
          totalInterest: 0,
          showDetails: false,
          startDate: DateTime.now().toFormat('LL/dd/yyyy'),
          totalInterestChart: null,
          years: null,
          greetings: ['hi', 'hello', 'buenos dias']
        }
      },
    methods: {
      addEvent(events, index, val)
        {
          if (!events[index]) { events[index] = []; }
          events[index].push(val);
        },
      addMortgage: function()
        {
          this.mortgages.push(
            {
              id: this.getUUID(),
              name: 'citi',
              balance: '326000',
              primary: true,
              rate: '8.3',
              term: '360',
              termUnit: 'month',
              asset:
                {
                  annualCurrentValuePctIncrease: '5',
                  closingDate: new Date('8/1/1999'),
                  currentValue: '326000',
                  downPayment: 0,
                  purchasePrice: '326000'
                },
              expenses:
                {
                  monthlyHOAFees: 0,
                  annualHOAFeesIncrease: 0,
                  annualInsurance: '650',
                  annualInsuranceIncrease: '2.3',
                  annualMaintenance: '400',
                  annualMaintenanceIncrease: '0.75',
                  annualMiscFees: '500',
                  annualMiscFeesIncrease: 0,
                  annualTaxes: '6500',
                  annualTaxesIncrease: '3.3',
                  pmi: 2.3,
                },
              rent:
                {
                  annualMaintenance: 0,
                  annualMaintenanceIncrease: 0,
                  annualManagementFee: 0,
                  annualManagementFeeIncrease: 0,
                  annualRentIncrease: 0,
                  monthlyManagementFee: 0,
                  monthlyManagementFeeIncrease: 0,
                  monthlyRent: 0,
                  annualMiscCosts: 0,
                  annualMiscCostsIncrease: 0,
                  vacancyRate: 0
                },
              expanded: false,
              selected: false,
              show: true
            });
        },
      aggregateSeries(dst, src)
        {
          for (let i in src.date)
            {
              if (typeof dst.date[i] === 'undefined')
                { 
                  dst.balance.push(src.balance[i]);
                  dst.date.push(src.date[i]);
                  dst.payment.push(src.payment[i]);
                  dst.principalPaid.push(src.principalPaid[i]);
                  dst.interestPaid.push(src.interestPaid[i]);
                  dst.totalInterest.push(src.totalInterest[i]);
                  dst.equity.push(src.equity[i]);
                  dst.income.push(src.income[i]);
                  dst.expenses.push(src.expenses[i]*-1);
                }
              else
                {
                  dst.balance[i]       = dst.balance[i] + src.balance[i];
                  dst.payment[i]       = dst.payment[i] + src.payment[i];
                  dst.principalPaid[i] = dst.principalPaid[i] + src.principalPaid[i];
                  dst.interestPaid[i]  = dst.interestPaid[i] + src.interestPaid[i];
                  dst.totalInterest[i] = dst.totalInterest[i] + src.totalInterest[i];      
                  dst.equity[i]        = dst.equity[i] + src.equity[i];
                  dst.income[i]        = dst.income[i] + src.income[i];
                  dst.expenses[i]      = dst.expenses[i] + src.expenses[i];
                }

              // Merge events
              let events = [];

              if (dst.events[i]) { events.push(...dst.events[i]); }
              if (src.events[i]) { events.push(...src.events[i]); }

              if (events.length > 0) 
                { dst.events[i] = events; }
              else 
                { dst.events[i] = null; }
            }
        },
      calc: function()
        {
          let act          = { balance: [], date: [], payment: [], principalPaid: [], interestPaid: [], totalInterest: [], equity: [], income: [], expenses: [], events: [] };
          let totalPeriods = 0;
          let years        = [];

          this.initialBalance = 0;

          for (let mortgage of this.mortgages)
            {
              let data = {};

              if (mortgage.show)
                {
                  data['act'] = this.calcMortgageSeries(mortgage);

                  mortgage['data'] = data;

                  totalPeriods = Math.max(totalPeriods, mortgage.data['act'].date.length);

                  this.initialBalance += Math.round(parseFloat(mortgage.balance)*100/100);
                }
            }

          this.showDetails = true;

          for (let mortgage of this.mortgages)
            {
              if (mortgage.show && mortgage.data['act'].date.length>0)
                {
                  for (let i=mortgage.data['act'].date.length; i<totalPeriods; i++)
                    {
                      mortgage.data['act'].balance.push(0);
                      mortgage.data['act'].date.push(new DateTime(mortgage.data['act'].date[i-1]).plus({months: 1}));
                      mortgage.data['act'].payment.push(0);
                      mortgage.data['act'].principalPaid.push(0);
                      mortgage.data['act'].interestPaid.push(0);
                      mortgage.data['act'].totalInterest.push(mortgage.data['act'].totalInterest[i-1]);
                    }
                }
            }

          for (let mortgage of this.mortgages)
            {
              if (mortgage.show)
                { this.aggregateSeries(act, mortgage.data['act']); }
            } 

          this.payment       = (act['payment'] && act['payment'].length > 0)?act.payment[0]:0;
          this.term          = act.date.length;
          this.totalInterest = (act['totalInterest'] && act['totalInterest'].length > 0)?act.totalInterest[act.totalInterest.length-1]:0; 

          this.chartData = {act: act};  

          let currentYear = act.date[0].year;
          let yearDetails = 
            { 
              date: currentYear,
              payment: 0,
              interestPaid: 0,
              principalPaid: 0,
              totalInterest: 0,
              balance: 0
            };

          for (let i=0; i<act.date.length; i++)    
            {
              if (act.date[i].year == currentYear)
                {
                  yearDetails.payment       += act.payment[i];
                  yearDetails.interestPaid  += act.interestPaid[i];
                  yearDetails.principalPaid += act.principalPaid[i];
                  yearDetails.totalInterest  = act.totalInterest[i];
                  yearDetails.balance        = act.balance[i];
                }  
              else              
                {
                  if (i < act.date.length-1)
                    { years.push(yearDetails); }
                  currentYear++;

                  yearDetails = 
                    { 
                      date:          currentYear,
                      payment:       act.payment[i],
                      interestPaid:  act.interestPaid[i],
                      principalPaid: act.principalPaid[i],
                      totalInterest: act.totalInterest[i],
                      balance:       act.balance[i]
                    };
                }
            }  
          years.push(yearDetails);

          this.years = years;  

          if (this.actualPaymentsChart)
            { this.actualPaymentsChart.destroy(); }
          this.createActualPaymentsChart();
          if (this.equityAndCashflowChart)
            { this.equityAndCashflowChart.destroy(); }
          this.createEquityAndCashflowChart();
          //if (this.totalInterestChart)
          //  { this.totalInterestChart.destroy(); }
          //this.createTotalInterestChart(); 

          this.calculated = true;

        },
      calcAnnualExpense(startDate, curDate, payMonth, initVal, annualRate)
        { 
          if (payMonth == -1)
            { return(initVal*((1+annualRate)**(curDate.year-startDate.year))/12); }
          return((curDate.month == payMonth)?initVal*((1+annualRate)**(curDate.year-startDate.year)):0); 
        },
      calcMonthlyExpense(startDate, curDate, monthlyVal, annualRate)
        { return(monthlyVal*((1+annualRate)**(curDate.year-startDate.year))); },
      calcMortgageSeries(mortgage)
        {
          let bal                             = this.convertInputToFloat(mortgage.balance);
          let closingDate                     = (mortgage.asset.closingDate && mortgage.asset.closingDate.length > 0)?DateTime.fromJSDate(new Date(mortgage.asset.closingDate)):null;
          let initialBal                      = bal;
          let start                           = this.startDate
          let rate                            = this.convertInputToFloat(mortgage.rate)/(12*100)
          let months                          = (mortgage.termUnit=='month')?this.convertInputToFloat(mortgage.term):this.convertInputToFloat(mortgage.term)*12;
          let currentValue                    = this.convertInputToFloat(mortgage.asset.currentValue);
          let annualCurrentValuePctIncrease   = this.convertInputToFloat(mortgage.asset.annualCurrentValuePctIncrease)/100;
          let monthlyRent                     = this.convertInputToFloat(mortgage.rent.monthlyRent);
          let annualRentIncrease              = this.convertInputToFloat(mortgage.rent.annualRentIncrease)/100;
          let vacancyRate                     = this.convertInputToFloat(mortgage.rent.vacancyRate)/100;

          let monthlyHOAFees                  = this.convertInputToFloat(mortgage.expenses.monthlyHOAFees);
          let annualHOAFeesIncrease           = this.convertInputToFloat(mortgage.expenses.annualHOAFeesIncrease)/100;
          let annualInsurance                 = this.convertInputToFloat(mortgage.expenses.annualInsurance);
          let annualInsuranceIncrease         = this.convertInputToFloat(mortgage.expenses.annualInsuranceIncrease)/100;
          let annualMaintenance               = this.convertInputToFloat(mortgage.expenses.annualMaintenance);
          let annualMaintenanceIncrease       = this.convertInputToFloat(mortgage.expenses.annualMaintenanceIncrease)/100;
          let annualMiscFees                  = this.convertInputToFloat(mortgage.expenses.annualMiscFees);
          let annualMiscFeesIncrease          = this.convertInputToFloat(mortgage.expenses.annualMiscFeesIncrease)/100;
          let annualTaxes                     = this.convertInputToFloat(mortgage.expenses.annualTaxes);
          let annualTaxesIncrease             = this.convertInputToFloat(mortgage.expenses.annualTaxesIncrease)/100;
          let pmi                             = this.convertInputToFloat(mortgage.expenses.pmi)/(12*100);

          let annualRentalMaintenance         = this.convertInputToFloat(mortgage.rent.annualMaintenance);
          let annualRentalMaintenanceIncrease = this.convertInputToFloat(mortgage.rent.annualMaintenanceIncrease)/100;          
          let annualManagementFee             = this.convertInputToFloat(mortgage.rent.annualManagementFee);
          let annualManagementFeeIncrease     = this.convertInputToFloat(mortgage.rent.annualManagementFeeIncrease)/100;
          let annualMiscCosts                 = this.convertInputToFloat(mortgage.rent.annualMiscCosts);
          let annualMiscCostsIncrease         = this.convertInputToFloat(mortgage.rent.annualMiscCostsIncrease)/100;
          let monthlyManagementFee            = this.convertInputToFloat(mortgage.rent.monthlyManagementFee);
          let monthlyManagementFeeIncrease    = this.convertInputToFloat(mortgage.rent.monthelyManagementFeeIncrease)/100;


          let data   =                     
            {
              balance: [],
              date: [],
              payment: [],
              principalPaid: [],
              interestPaid: [],
              totalInterest: [],
              equity: [],
              income: [],
              expenses: [],
              events: []
            };
            
          let date          = (new DateTime(start)).plus({months: 1});
          let payment       = bal*(rate*((1+rate)**months))/(((1+rate)**months)-1);
          let totalInterest = 0;

          while (bal > 1)
            {
              let interestPaid   = bal*(rate);
              let principalPaid  = payment-interestPaid;
              
              totalInterest     += interestPaid;
              bal               -= principalPaid;

              data.date.push(date);
              data.balance.push(Math.round(bal*100)/100);
              data.payment.push(Math.round(payment*100)/100);
              data.principalPaid.push(Math.round(principalPaid*100)/100);
              data.interestPaid.push(Math.round(interestPaid*100)/100);
              data.totalInterest.push(Math.round(totalInterest*100)/100);

              date = new DateTime(date).plus({months: 1});
            }

          // Initialize Events array
          data.events = new Array(data.date.length).fill(null);

          if (mortgage.primary)
            {
              for (let i in data.date)
                {
                  let annualExpenses = 0; 
                    
                  annualExpenses += this.calcAnnualExpense(data.date[0], data.date[i], (this.evenlyDistributeExpenses)?-1:1, annualTaxes, annualTaxesIncrease);
                  annualExpenses += this.calcAnnualExpense(data.date[0], data.date[i], (this.evenlyDistributeExpenses)?-1:1, annualMaintenance, annualMaintenanceIncrease);
                  annualExpenses += this.calcAnnualExpense(data.date[0], data.date[i], (this.evenlyDistributeExpenses)?-1:1, annualInsurance, annualInsuranceIncrease);
                  annualExpenses += this.calcAnnualExpense(data.date[0], data.date[i], (this.evenlyDistributeExpenses)?-1:1, annualMiscFees, annualMiscFeesIncrease);
                  annualExpenses += this.calcMonthlyExpense(data.date[0], data.date[i], monthlyHOAFees, annualHOAFeesIncrease);
                  annualExpenses += this.calcAnnualExpense(data.date[0], data.date[i], (this.evenlyDistributeExpenses)?-1:1, annualRentalMaintenance, annualRentalMaintenanceIncrease);
                  annualExpenses += this.calcAnnualExpense(data.date[0], data.date[i], (this.evenlyDistributeExpenses)?-1:1, annualManagementFee, annualManagementFeeIncrease);
                  annualExpenses += this.calcAnnualExpense(data.date[0], data.date[i], (this.evenlyDistributeExpenses)?-1:1, annualMiscCosts, annualMiscCostsIncrease);
                  annualExpenses += this.calcMonthlyExpense(data.date[0], data.date[i], monthlyManagementFee, monthlyManagementFeeIncrease);

                  if (pmi > 0)
                    {
                      if (i > 0)
                        {
                          let pctEquity = data.balance[i-1]/data.equity[i-1];
                          let halfway   = false;
                          let ltv       = data.balance[i-1]/(mortgage.asset.purchasePrice - mortgage.asset.downPayment);

                          if (closingDate)
                            {
                              let endDate     = DateTime.fromJSDate(new Date(data.date.slice(-1)));
                              let diff        = endDate.diff(closingDate, 'months');
                              let halfwayDate = closingDate.plus({months: diff.toObject().months/2});
                              
                              if (data.date[i] > halfwayDate) { halfway = true; }   
                            }
    
                          if (!halfway && ltv > 0.78) // pctEquity > 0.8 || !halfway || ltv > 0.78)
                            { annualExpenses += data.balance[i-1] * (pmi); }
                          else
                            {
                              if (!Object.prototype.hasOwnProperty.call(data, 'lastYearOfPMI'))
                                { 
                                  data['lastYearOfPMI'] = data.date[i-1];

                                  if (!data.events[i]) { data.events[i] = []; }
                                  if (ltv <= 0.78)
                                    { this.addEvent(data.events, i, `PMI is no longer required since your '${mortgage.name}' loan balance has reached 78% of the original purchase price.`); }
                                  else if (halfway)
                                    { this.addEvent(data.events, i, `PMI is no longer required since 50% of your '${mortgage.name}' mortgage payments have been paid.`); }
                                  else
                                    { this.addEvent(data.events, i, `PMI is no longer required since your '${mortgage.name}' loan balance has reached 80% of your home's value.`); }
                                }
                            }
                        }
                      else
                        { annualExpenses += initialBal * (pmi); }
                    }

                  data.equity.push(Math.round(((currentValue*((1+(annualCurrentValuePctIncrease/12))**i))-data.balance[i])*100)/100);
                  data.income.push(Math.round((this.calcMonthlyExpense(data.date[0], data.date[i], monthlyRent*(1-vacancyRate), annualRentIncrease))*100)/100);
                  data.expenses.push(Math.round(annualExpenses*100)/100);
                }

              this.addEvent(data.events, data.date.length-1, `Mortgage '${mortgage.name}' as been paid off.`);
            }
          else
            {
              data.equity   = Array(data.date.length).fill(0);
              data.income   = Array(data.date.length).fill(0);
              data.expenses = Array(data.date.length).fill(0);
            }
          
          return(data);
        },
      convertInputToFloat(input)
        {
          let f = parseFloat(input);
          return((isNaN(f))?0:f);
        },
      copyMortgage: function(index) 
        { 
          this.mortgages.splice(index, 0, JSON.parse(JSON.stringify(this.mortgages[index])));
          this.mortgages[index+1].primary = false;
          this.mortgages[index+1].expanded = false; 
        },
      createActualPaymentsChart()
        {
          let data = {labels:[], datasets:[]};

          for (let i=0; i<this.chartData['act'].date.length; i++)
            { data.labels.push(this.chartData['act'].date[i].toFormat('LL/yy')); }

          data.datasets.push(
            {
              label: 'Balance',
              data: this.chartData['act'].balance,
              type: 'line',
              fill: false,
              borderColor: '#aaaaaa',
              borderWidth: 2,
              yAxisID: 'y-bal'
            });
          data.datasets.push(
            {
              label: 'Interest Paid',
              backgroundColor: '#483D8B',
              data: this.chartData['act'].interestPaid,
              yAxisID: 'y-payments'
            });
          data.datasets.push(
            {
              label: 'Principal Paid',
              backgroundColor: '#87cefa',
              data: this.chartData['act'].principalPaid,
              yAxisID: 'y-payments'
            });

           // eslint-disable-next-line no-unused-vars
          this.actualPaymentsChart = new Chart('actualPaymentsChart', 
            {
              type: 'bar',
              responsive: true,
              maintainAspectRatio: false,
              data: data,
              options: 
                {
                  scales: 
                    {
                      xAxes: 
                        [
                          {
                            stacked: true
                          }],
                      yAxes: 
                        [
                          {
                            id: 'y-bal',
                            label: 'Balance',
                            stacked: true,
                            display: true,
                            backgroundColor: '#888888',
                            type: 'linear',
                            position: 'left',
                            scaleLabel: 
                              {
                                display: true,
                                labelString: 'Balance'
                              }
                          },
                          {
                            id: 'y-payments',
                            label: 'Interest and Principal Paid',
                            stacked: true,
                            display: true,
                            backgroundColor: '#888888',
                            type: 'linear',
                            position: 'right',
                            scaleLabel: 
                              {
                                display: true,
                                labelString: 'Interest and Principal Paid'
                              }
                          }
                        ]
                    }
                }
            });

          //$('#actualPaymentsChart').replaceWith($('<canvas id="actualPaymentsChart" max-width="100" max-height="100"></canvas>'));
        },
      createEquityAndCashflowChart()
        {
          let data         = {labels:[], datasets:[]};
          let showIncome   = false;
          let showExpenses = false;

          for (let i=0; i<this.chartData['act'].date.length; i++)
            { 
              data.labels.push(this.chartData['act'].date[i].toFormat('LL/yy')); 
              if (this.chartData['act'].income[i] != 0)   { showIncome = true; }
              if (this.chartData['act'].expenses[i] != 0) { showExpenses = true; }
            }

          data.datasets.push(
            {
              label: 'Equity',
              data: this.chartData['act'].equity,
              type: 'line',
              fill: false,
              borderColor: '#aaaaaa',
              borderWidth: 2,
              yAxisID: 'y-equity'
            });
          data.datasets.push(
            {
              label: 'Income',
              backgroundColor: '#483D8B',
              data: this.chartData['act'].income,
              yAxisID: 'y-income-and-expenses'
            });
          data.datasets.push(
            {
              label: 'Expenses',
              backgroundColor: '#87cefa',
              data: this.chartData['act'].expenses,
              yAxisID: 'y-income-and-expenses'
            });


           // eslint-disable-next-line no-unused-vars
          this.equityAndCashflowChart = new Chart('equityAndCashflowChart', 
            {
              type: 'bar',
              responsive: true,
              maintainAspectRatio: false,
              data: data,
              options: 
                {
                  scales: 
                    {
                      xAxes: 
                        [
                          {
                            stacked: true
                          }],
                      yAxes: 
                        [
                          {
                            id: 'y-equity',
                            label: 'Equity',
                            stacked: true,
                            display: true,
                            backgroundColor: '#888888',
                            tickes:
                              { beginAtZero: false },
                            type: 'linear',
                            position: 'left',
                            scaleLabel: 
                              {
                                display: true,
                                labelString: 'Equity'
                              }
                          },
                          {
                            id: 'y-income-and-expenses',
                            label: 'Income',
                            stacked: true,
                            display: showIncome || showExpenses,
                            backgroundColor: '#888888',
                            type: 'linear',
                            position: 'right',
                            ticks: 
                              {
                                beginAtZero: false,
                                count: (context) => { return(context.chart.config.options.scales.yAxes[0].ticks.count); },
                                suggestedMax: 1000
                              },
                            scaleLabel: 
                              {
                                display: true,
                                labelString: 'Income & Expenses'
                              }
                          }
                        ]
                    }
                }
            });

          //$('#actualPaymentsChart').replaceWith($('<canvas id="actualPaymentsChart" max-width="100" max-height="100"></canvas>'));
        },
      createTotalInterestChart()
        {
          // eslint-disable-next-line no-unused-vars
          this.totalInterestChart = new Chart('totalInterestChart', 
            {
              type: 'pie', 
              responsive: true,
              maintainAspectRatio: false,
              data: 
                {
                  datasets: 
                    [
                      {
                        data: [Math.round(this.totalInterest*100)/100, this.initialBalance],
                        backgroundColor: ['#aaaaaa', '#6574de']
                      }
                    ],

                  // These labels appear in the legend and in the tooltips when hovering different arcs
                  labels: 
                    [
                      'Total Interest Paid',
                      'Principal Paid'
                    ]
                }
            });
        },
      currencyFormat: function(val)
        { 
          let temp = this.numFormat.format(val);
          return(temp); 
        },
      dateFormat(val)
        { return(val.toFormat('LL/dd/yyyy')); },
      deleteMortgage: function(index) 
        { 
          this.mortgages.splice(index, 1);
          this.calc();
        },
      deleteSelected: function()
        {
          for(let i=this.mortgages.length-1; i>=0; i--)
            {
              if (this.mortgages[i].form.delete)
                { this.deleteMortgage(i); }
            }
        },
      getUUID: function ()
        {
          let dt   = new Date().getTime();
          let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) =>
            {
              let r = (dt + Math.random()*16)%16 | 0;
              dt = Math.floor(dt/16);
              return (c=='x' ? r :(r&0x3|0x8)).toString(16);
            });
          return uuid;
        },
      hideMortgage: function(index)
        { 
          this.mortgages[index].show = false;
          this.calc();
        },
      saveMortgages: function()
        { return(null); },
      setTermUnit(mortgage, unit)
        { mortgage.termUnit = unit; },
      showMortgage: function(index)
        { 
          this.mortgages[index].show = true;
          this.calc();
        },
      toggleDetails: function(mortgage)
        { mortgage.expanded = !mortgage.expanded; },
      togglePrimary: function(mortgage)
        {
          mortgage.primary = !mortgage.primary;

          if (!mortgage.primary)
            { mortgage.expanded = false; }
        },
      toggleSelectAll: function(event)
        {
          let state = event.srcElement.checked;

          for (let mortgage of this.mortgages)
            { mortgage.selected = state; }
        }
    }
}


</script>
import 

<style scoped lang="scss">
  @import '../../assets/css/tailwind.css';

  #mortgage-table,tr {
    padding-bottom: 0px
  }
</style>
