import Vue from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'
import About from '../views/About.vue'
import Home from '../views/Home.vue'
import CarLoanCalculator from '../views/calculators/CarLoanCalculator'
import CollegeLoanCalculator from '../views/calculators/CollegeLoanCalculator'
import CreditCardCalculator from '../views/calculators/CreditCardCalculator'
import Education from '../views/Education.vue'
import Finances from '../views/Finances.vue'
import Login from '../views/Login.vue'
import Mortgage from '../views/Mortgage.vue'
import MortgageCalculator from '../views/calculators/MortgageCalculator'
import Retirement from '../views/Retirement'


const routes = 
  [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      component: About
    },
    {
      path: '/calculators/car-loans',
      name: 'CarLoanCalculator',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about" */ '../views/calculators/CarLoanCalculator.vue')
      component: CarLoanCalculator
    },
    {
      path: '/calculators/college-loans',
      name: 'CollegeLoanCalculator',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about" */ '../views/calculators/CollegeLoanCalculator.vue')
      component: CollegeLoanCalculator
    },
    {
      path: '/calculators/credit-cards',
      name: 'CreditCardCalculator',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about" */ '../views/calculators/CreditCardCalculator.vue')
      component: CreditCardCalculator
    },
    {
      path: '/education',
      name: 'Education',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about" */ '../views/Education.vue')
      component: Education
    },
    {
      path: '/finances',
      name: 'Finances',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about" */ '../views/Finances.vue')
      component: Finances
    },
    {
      path: '/login',
      name: 'Login',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
      component: Login
    },
    {
      path: '/calculators/mortgages',
      name: 'MortgageCalculator',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about" */ '../views/calculators/MortgageCalculator.vue')
      component: MortgageCalculator
    },
    {
      path: '/retirement',
      name: 'Retirement',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about" */ '../views/Retirement.vue')
      component: Retirement
    },
    {
      path: '/mortgage',
      name: 'Mortgage',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about" */ '../views/Mortgage.vue')
      component: Mortgage
    }
  ]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

//Vue.use(router);

export default router
