<template lang="html">

  <section class="mortgage">
    <div class="article-list">
        <div class="container" id="main-container"><img class="img-fluid shadow" id="lookout-header" src="img/mortgage2.jpg">
            <div class="row justify-content-center articles" style="margin-top: 15px;font-family: 'Alegreya Sans SC', sans-serif;">
                <div class="col">
                    <ol class="breadcrumb" style="background-color: rgb(255,255,255);font-family: Actor, sans-serif;font-size: 12pt;color: rgb(55,59,62);">
                        <li class="breadcrumb-item"><a href="#/"><span style="color: rgb(118,119,119);">Home</span></a></li>
                        <li class="breadcrumb-item"><a href="#"><span style="color: rgb(118,119,119);">Mortgage</span></a></li>
                    </ol>
                    <p class="text-start" style="font-size: 12pt;font-family: Average, serif;color: rgb(0,0,0); margin-bottom: 40px">So, you're looking to purchase your home, or begin investing in real estate.  Before you do, there are some important things to consider:</p>
                    <div >
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" role="presentation"><a class="nav-link active" role="tab" data-toggle="tab" data-bs-target="#tab-1">Pre-Purchase</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-2">Financing</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-3">Insurance</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-4">Renting</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-5">Foreclosure</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#tab-6">Selling</a></li>
                        </ul>
                        <div class="tab-content" style="font-size: 12pt;font-family: Average, serif;color: rgb(0,0,0);">
                            <div class="tab-pane active text-start" role="tabpanel" style="margin-top: 20px" id="tab-1">
                              <p>When taking out a mortgage, there are several pre-purchase considerations to keep in mind, including:</p>

                              <br>
                              <h4>Credit Score</h4>
                              <p>A good credit score is essential for obtaining a favorable mortgage rate. Before applying for a mortgage, it's important to check your credit score and take steps to improve it if necessary.</p>
                              <p>Your credit score is one of the most important factors that lenders consider when deciding whether to approve you for a mortgage and what interest rate to offer you. Your credit score is a numerical representation of your creditworthiness based on factors such as your credit history, outstanding debts, and payment history. Lenders use your credit score to assess the risk of lending you money and to determine the interest rate you qualify for.</p>
                              <p>Having a good credit score can make it easier to get approved for a mortgage and can also result in a lower interest rate, which can save you thousands of dollars over the life of the loan. A higher credit score indicates that you are more likely to repay the loan on time and in full, which makes you less risky to lenders.</p>
                              <p>On the other hand, a low credit score can make it more difficult to qualify for a mortgage and can result in a higher interest rate, which can increase the overall cost of the loan. A lower credit score may also require a larger down payment or additional collateral to secure the loan.</p>
                                
                                      
                              <br>
                              <h4>Property Location and Condition</h4>
                              <p>The location and condition of the property can affect the value and potential resale value, so be sure to do your due diligence before making an offer.</p>

                              <p>Overall, it's important to carefully consider all aspects of a mortgage before making a purchase to ensure that you make an informed decision that fits your financial situation and goals.</p>



                            </div>
                            <div class="tab-pane text-start" role="tabpanel" id="tab-2">

                              <br>
                              <h4>Down Payment</h4>
                              <p>The amount of down payment required for a mortgage can vary depending on the type of loan and the lender's requirements. However, in general, most lenders require a down payment of at least 3% to 20% of the home's purchase price.</p>
                              <p>For example, for a $300,000 home, a down payment of 3% would be $9,000, while a down payment of 20% would be $60,000. The higher the down payment, the less you will need to borrow, which can result in a lower monthly mortgage payment and less interest paid over the life of the loan.</p>
                              <p>A down payment of less than 20% may also require the borrower to pay private mortgage insurance (PMI), which is an insurance policy that protects the lender in case the borrower defaults on the loan. PMI can add to the cost of the mortgage, so it's important to factor this into your budget when deciding how much to put down.</p>
                              <p>In addition to the down payment, there are also other costs associated with buying a home, such as closing costs, which can range from 2% to 5% of the purchase price. It's important to factor in all of these costs when determining how much you can afford to put down on a mortgage.</p>
                                
                              <br>
                              <h4>Monthly Budget</h4>
                              <!-- <p>Make sure you have a clear understanding of your monthly budget, including your income and expenses, so you can determine how much you can afford to pay each month toward your mortgage.</p>-->
                              <p>A monthly budget is very important in financing a mortgage because it helps you determine how much you can afford to pay for your mortgage each month. When you take out a mortgage, you are committing to making monthly payments for a long period of time, typically 15-30 years. It's important to ensure that you can comfortably make these payments on a regular basis without sacrificing your other financial obligations or lifestyle.</p>
                              <p>Your monthly budget should take into account your income, expenses, and savings goals. Your mortgage payment will be one of your biggest expenses, so you need to make sure you can afford it. This includes not only the principal and interest payments but also property taxes, insurance, and any other fees associated with the mortgage.</p>
                              <p>If you're not sure how much you can afford for a mortgage, you can use an online mortgage calculator to get an estimate. However, it's important to keep in mind that this is just a rough estimate, and you should also consider other factors such as your credit score, down payment, and other debts or financial obligations.</p>
                              <p>Ultimately, having a monthly budget that includes your mortgage payment will help you stay on track financially and avoid any potential financial difficulties down the line.</p>

                              <br>
                              <h4>Closing Costs</h4>
                              <!-- <p>In addition to the down payment, there are also closing costs to consider. These can include things like appraisal fees, title insurance, and attorney fees.</p> -->
                              <p>Closing costs are fees and expenses that must be paid by the buyer and seller during the closing process when buying a house. These costs typically range from 2-5% of the total purchase price of the home and can vary depending on the location, lender, and other factors.</p>
                              <p>Here is a breakdown of some common closing costs when buying a house:</p>
                              <ol>
                                <li><b>Loan-Related Costs:</b> These include fees associated with obtaining a mortgage loan, such as origination fees, credit report fees, appraisal fees, and discount points.</li>
                                <li><b>Title and Escrow Costs:</b> These include fees for title searches, title insurance, and escrow services. Title insurance protects the buyer and lender from any title defects or claims against the property.</li>
                                <li><b>Inspection and Appraisal Costs:</b> These include fees for home inspections, pest inspections, and property appraisals. These are typically paid by the buyer and ensure that the property is in good condition and worth the purchase price.</li>
                                <li><b>Prepaid Expenses:</b> These include fees for prepaid property taxes, prepaid interest, and homeowners insurance premiums.</li>
                                <li><b>Attorney Fees:</b> If an attorney is used to review and prepare legal documents, they will typically charge a fee for their services.</li>
                                <li><b>Recording Fees:</b> These are fees paid to the local government for recording the deed and other legal documents related to the sale of the property.</li>
                                <li><b>Other Costs:</b> Other miscellaneous costs may include courier fees, wire transfer fees, and other administrative expenses.</li>
                              </ol> 
                                <p>It's important to note that closing costs can vary widely depending on the location and other factors. Buyers should ask their lender for a Loan Estimate, which breaks down the estimated closing costs, so they can prepare accordingly. Sellers should also be aware of their closing costs, which may include fees for paying off their mortgage and real estate agent commissions.</p>
                              <br>
                              <h4>Interest Rate</h4>
                              <!-- <p>The interest rate you receive on your mortgage will have a big impact on your monthly payment and the overall cost of the loan. Shop around to find the best rate and loan terms for your situation.</p> -->
                              <p>An interest rate plays a significant role in determining the overall cost of a mortgage. It affects both the monthly payment amount and the total amount of interest paid over the life of the loan. Here's how:</p>
                              <ul>
                                <li><b>Monthly Payment Amount:</b> The interest rate is one of the main factors that determine the monthly payment amount for a mortgage. A higher interest rate will result in a higher monthly payment, while a lower interest rate will result in a lower monthly payment. This is because the interest rate determines the amount of interest that accrues on the loan each month, which is added to the principal to determine the total payment amount.</li>
                                <li><b>Total Interest Paid:</b> The interest rate also affects the total amount of interest paid over the life of the loan. A higher interest rate will result in a higher total amount of interest paid, while a lower interest rate will result in a lower total amount of interest paid. This is because the interest rate determines the cost of borrowing money over time.</li>
                              </ul>
                              <p>For example, if you have a 30-year fixed-rate mortgage for $250,000 with an interest rate of 4%, your monthly payment would be $1,193.54, and you would pay a total of $179,673.18 in interest over the life of the loan. If the interest rate were to increase to 5%, your monthly payment would increase to $1,342.05, and you would pay a total of $223,813.</p>
                              <br>
                              <h4>Loan Term</h4>
                              <!-- <p>Consider whether you want a 15- or 30-year mortgage, as well as whether you want a fixed or adjustable interest rate. Each option has its pros and cons, so make sure you understand the differences.</p> -->
                              <p>The loan term is the length of time over which a borrower repays their mortgage loan. A longer loan term means lower monthly payments, but a higher total amount of interest paid over the life of the loan, while a shorter loan term means higher monthly payments but a lower total amount of interest paid. Here are some ways in which the loan term affects a mortgage:</p>
                              <ul>
                                <li><b>Monthly Payments:</b> A longer loan term will result in lower monthly payments because the total loan amount is spread out over a longer period of time. For example, a 30-year mortgage with a $300,000 loan amount and a 4% interest rate would result in a monthly payment of $1,432. Conversely, a 15-year mortgage with the same loan amount and interest rate would result in a higher monthly payment of $2,219.</li>
                                <li><b>Total Interest Paid:</b> The longer the loan term, the more total interest a borrower will pay over the life of the loan. This is because interest accrues over time, and a longer loan term means more time for interest to accumulate. Using the same example as above, the total interest paid on a 30-year mortgage would be $215,609, while the total interest paid on a 15-year mortgage would be $79,337.</li>
                                <li><b>Equity:</b> A shorter loan term means that a borrower will build equity in their home faster than they would with a longer loan term. Equity is the difference between the home's value and the outstanding mortgage balance. As a borrower makes payments, the amount of equity they have in their home increases. With a shorter loan term, the borrower will pay down the mortgage balance more quickly, which means they will have more equity in their home sooner.</li>
                              </ul>


                              <p>When considering the length of time you will be paying off your mortgage, it is important that a mortgage not be not be considered as a stand-alone investment, rather, it should be a component of a holistic view of your entire investment portfolio:</p>
                              <ul>
                                <li>If you have other financial obligations, such as credit card debt or a mortgage, it may be more important to focus on paying off those debts first, as they likely have higher interest rates and can accrue more interest over time.</li>
                                <li>If the loan is a fixed-rate loan, the interest rate will remain the same over the entire loan term, so there is no risk of the interest rate increasing over time. This means that the total interest paid over the life of the loan will be predictable and can be factored into your budget.</li>
                                <li>By stretching out the loan payments over a longer period of time, you may have more flexibility with your monthly budget and cash flow. This could allow you to put more money towards other expenses or investments, or to build up your emergency fund or savings.</li>
                              </ul>
                              <!-- <p>However, it is important to note that paying off a loan over a longer period of time will result in paying more interest overall. If you have the ability to pay off the loan faster, it may be a better financial decision in the long run to do so, as you will save money on interest charges.</p> -->
                              <p>Ultimately, the decision to pay off a low interest loan over a longer period of time should be based on your individual financial situation, priorities, and goals.</p>





                              <!-- <p>In summary, the loan term affects the mortgage by impacting the monthly payment amount, the total amount of interest paid, and the rate at which the borrower builds equity in their home. Borrowers must choose a loan term that </p> -->




                              <!-- In addition to affecting the monthly payment and total cost of the loan, the loan term also affects the equity in the home. Equity is the difference between the value of the home and the outstanding mortgage balance. Here's how the loan term affects equity:

Paying Down Principal: As a borrower makes mortgage payments, a portion of the payment goes toward paying down the principal balance of the loan. A shorter loan term means that the borrower will pay down the principal balance faster, resulting in more equity in the home at a quicker rate. A longer loan term means that the borrower will pay down the principal balance more slowly, resulting in less equity in the home at a slower rate.

Appreciation: As the value of the home increases over time, equity in the home also increases. However, the loan term does not directly affect home appreciation. Regardless of the loan term, if the home value increases, equity in the home will also increase.

Amortization Schedule: The loan term also affects the amortization schedule, which shows how much of each payment goes toward paying down the principal balance and how much goes toward paying interest. A shorter loan term means that more of each payment goes toward paying down the principal balance, resulting in more equity in the home at a quicker rate. A longer loan term means that more of each payment goes toward paying interest, resulting in less equity in the home at a slower rate.

For example, let's say a borrower takes out a $200,000 mortgage loan with an interest rate of 4%. If they choose a 30-year loan term, their monthly payment will be $955, and after 5 years, their outstanding mortgage balance will be approximately $183,000. If they choose a 15-year loan term, their monthly payment will be $1,479, and after 5 years, their outstanding mortgage balance will be approximately $118,000. This means that after 5 years, the borrower with the 15-year loan term will have more equity in their home than the borrower with the 30-year loan term.

It's important for borrowers to consider the loan term and its impact on equity when choosing a mortgage. While a longer loan term may result in a lower monthly payment, it may also result in less equity in the home over time. -->


                            </div>
                            <div class="tab-pane text-start" role="tabpanel" id="tab-3">

                              <br>
                              <p>The specific type of insurance required for a home will depend on various factors, including the location, type of home, and any mortgage requirements. However, most homeowners are typically required to have homeowners insurance, which is designed to protect the home and its contents from damage or loss.</p>
                              <p>Homeowners insurance typically provides coverage for a range of perils, including damage from fire, theft, vandalism, and natural disasters like windstorms and hail. It may also include liability coverage, which can protect the homeowner if someone is injured on the property or if the homeowner is found to be responsible for damage to someone else's property.</p>
                              <p>If the home is located in a flood-prone area, flood insurance may also be required. Flood insurance is typically not included in standard homeowners insurance policies, but can be purchased separately.</p>
                              <p>In addition to homeowners insurance, some mortgage lenders may require borrowers to have mortgage insurance, particularly if the down payment is less than 20% of the home's value. Mortgage insurance is designed to protect the lender if the borrower defaults on the loan.</p>
                              
                              <br>
                              <h4>Private Mortgage Insurance</h4>
                              <p>Private Mortgage Insurance, or PMI, is typically required by lenders when a homebuyer has a down payment of less than 20% of the purchase price of the home. PMI protects the lender in case the borrower defaults on the mortgage loan.</p>

                              <p>Here are a few things you should know about PMI insurance:</p>
                              <ul>
                                <li><b>Cost:</b> The cost of PMI varies depending on the size of the down payment, the loan amount, and the borrower's credit score. The cost of PMI is typically added to the monthly mortgage payment.</li>
                                <li><b>Cancellation:</b> PMI can be cancelled once the borrower has paid down the loan to 80% of the home's original value, either through regular mortgage payments or through appreciation in the home's value. Once the borrower has paid down the loan to 78% of the original value, the lender is required to cancel the PMI.</li>
                                <li><b>Duration:</b> The length of time that PMI is required can vary, but it is typically required for the first few years of the loan. For example, if you have a 30-year mortgage, you might be required to pay PMI for the first 5-10 years.</li>
                                <li><b>Coverage:</b> PMI protects the lender, not the borrower. If the borrower defaults on the loan and the lender forecloses on the property, the PMI will reimburse the lender for the amount of the loan that is not recouped through the foreclosure sale.</li>
                              </ul>
                              
                              <p>If you are considering purchasing a home with a down payment of less than 20%, it's important to understand the requirements and costs of PMI. Be sure to ask your lender about PMI and factor the cost into your budget when considering your home purchase.</p>
                              <p>It's important to carefully review the insurance requirements for a home and to make sure that you have adequate coverage to protect your investment and your finances.</p>


                            </div>
                            <div class="tab-pane text-start" role="tabpanel" id="tab-4">

                              <br>
                              <p>Renting out a house or apartment can be a lucrative investment, but it's important to understand the responsibilities and legal obligations that come with being a landlord. Here are some key things you need to know as a landlord when renting a house or apartment:</p>

                              <h4>Legal Requirement</h4>
                              <!-- <p>As a landlord, you need to comply with local, state, and federal laws that govern rental properties. This includes fair housing laws, safety codes, and regulations regarding security deposits, eviction proceedings, and lease agreements.</p> -->
                              
                              <p>As a landlord, you have a legal obligation to comply with various laws and regulations at the federal, state, and local levels. Here are some of the key legal requirements that you need to be aware of:</p>
                              <ul>
                                <li><b>Fair Housing Laws::</b> These are federal and state laws that prohibit discrimination in housing based on race, color, religion, sex, national origin, familial status, and disability. As a landlord, you must comply with fair housing laws and ensure that your rental practices are non-discriminatory.</li>
                                <li><b>Landlord-Tenant Laws::</b> These are state-specific laws that govern the landlord-tenant relationship. They cover issues such as rent increases, security deposits, evictions, and lease agreements. As a landlord, you must comply with the landlord-tenant laws in your state.</li>
                                <li><b>Safety and Building Codes::</b> Rental properties must comply with state and local safety and building codes. This includes ensuring that the property is structurally sound, has working smoke detectors and carbon monoxide detectors, and meets fire safety standards.</li>
                                <li><b>Security Deposits::</b> Most states have laws regulating the use of security deposits. This includes limits on the amount of the deposit, requirements for returning the deposit at the end of the lease, and rules for using the deposit to cover damages or unpaid rent.</li>
                                <li><b>Lease Agreements::</b> Lease agreements must comply with state and local laws, and must include certain information such as the rental amount, length of the lease, security deposit requirements, and any restrictions or rules that apply to the property.</li>
                                <li><b>Privacy Rights::</b> Tenants have a right to privacy in their rental units. As a landlord, you must provide notice before entering the rental unit, and you cannot enter the unit without the tenant's permission except in certain circumstances.</li>
                                <li><b>Maintenance and Repairs::</b> As a landlord, you are responsible for maintaining the property and ensuring it is safe and habitable for your tenants. This includes regular maintenance, repairs, and addressing any safety hazards.</li>
                                <li><b>Rent Collection::</b> You are responsible for collecting rent from your tenants on time and in full. You should establish clear procedures for rent collection, including late fees and penalties for non-payment.</li>
                                <li><b>Tenant Screening::</b> It's important to screen potential tenants to ensure they are reliable and responsible. This may include conducting background checks, verifying employment and income, and contacting references.</li>
                                <li><b>Insurance:</b> As a landlord, you should have adequate insurance coverage to protect your property and assets. This may include property insurance, liability insurance, and loss of rental income insurance.</li>
                              </ul>
                              <p>By understanding and complying with these legal requirements, you can ensure a successful and profitable rental experience while avoiding legal disputes and liability.</p>

                              
                              
                              
                              <h4>Rental Agreements</h4>
                              <!-- <p>Before renting out your property, you should draft a rental agreement that outlines the terms and conditions of the lease. This includes the rental price, security deposit requirements, length of the lease, and any restrictions or rules that apply to the property.</p> -->
                              
                              <p>Rental agreements are essential documents that outline the terms and conditions of a rental arrangement between a landlord and tenant. They serve as legal contracts that protect the rights and interests of both parties, and they are critical for landlords for several reasons:</p>
                              <ul>
                                <li><b>Clarify Expectations:</b> Rental agreements help landlords and tenants understand their respective rights and responsibilities. They outline the terms of the lease, including the amount of rent, payment due dates, security deposit requirements, and move-in and move-out dates. By establishing clear expectations at the outset, landlords can avoid misunderstandings and disputes down the road.</li>
                                <li><b>Legal Protection:</b> Rental agreements provide legal protection for landlords. They establish the landlord's right to collect rent and take legal action if the tenant fails to pay. They also outline the consequences of lease violations, such as eviction or forfeiture of the security deposit.</li>
                                <li><b>Protect Property:</b> Rental agreements can also help protect the rental property. They outline restrictions on the use of the property, including prohibitions on smoking or keeping pets. They also establish guidelines for maintaining the property, including responsibilities for repairs and maintenance.</li>
                                <li><b>Prevent Disputes:</b> Rental agreements can help prevent disputes between landlords and tenants. By clearly outlining the terms of the lease, including rent payment dates, maintenance requirements, and restrictions on the use of the property, landlords can minimize the risk of misunderstandings and disputes.</li>
                                <li><b>Easy to Enforce:</b> Rental agreements are legally binding contracts, which means that they can be enforced in court if necessary. This can be especially important if a tenant violates the terms of the lease or fails to pay rent. By having a signed rental agreement in place, landlords can more easily pursue legal action to protect their interests.</li>
                              </ul>
                              <p>In summary, rental agreements are critical documents for landlords. They help establish clear expectations, provide legal protection, protect the rental property, prevent disputes, and are easy to enforce. By taking the time to create a comprehensive rental agreement, landlords can ensure a successful and profitable rental experience.</p>
                              
                              <h4>Tenant Screening</h4>
                              <!-- <p>It's important to screen potential tenants to ensure they are reliable and responsible. This may include conducting background checks, verifying employment and income, and contacting references.</p> -->
                              
                              <p>Tenant screening is the process by which landlords assess the eligibility of prospective tenants before renting out their properties. It involves collecting and evaluating information about a tenant's credit history, rental history, criminal record, and employment status, among other factors. Here are some ways in which tenant screening is used by landlords:</p>
                              <ul>
                                <li><b>Assessing Financial Responsibility:</b> Landlords use tenant screening to evaluate a prospective tenant's financial responsibility, including their ability to pay rent on time. They review credit reports, employment verification, and income statements to ensure that tenants have the financial capacity to meet their rental obligations.</li>
                                <li><b>Evaluating Rental History:</b> Tenant screening also allows landlords to evaluate a prospective tenant's rental history. They check for any evictions or lease violations and contact previous landlords to verify the tenant's rental history, including their ability to maintain the property and pay rent on time.</li>
                                <li><b>Ensuring a Safe Environment:</b> Tenant screening can also help landlords ensure a safe environment for all tenants. They check for criminal records, sex offender registries, and other public records to ensure that tenants do not pose a threat to the safety and well-being of other tenants.</li>
                                <li><b>Reducing Risk:</b> By conducting thorough tenant screenings, landlords can reduce their risk of rental income loss and property damage. They can identify potential red flags that may indicate a tenant's inability or unwillingness to meet their rental obligations or respect the property.</li>
                                <li><b>Compliance with Fair Housing Laws:</b> Tenant screening helps landlords comply with fair housing laws, which prohibit discrimination based on factors such as race, color, national origin, religion, sex, familial status, or disability. By using a standardized screening process, landlords can avoid discrimination and ensure equal treatment of all tenants.</li>
                              </ul>
                              <p>In summary, tenant screening is a critical tool for landlords to assess the eligibility of prospective tenants, evaluate financial responsibility and rental history, ensure a safe environment, reduce risk, and comply with fair housing laws. By conducting thorough screenings, landlords can make informed decisions about who to rent to and minimize the risk of rental income loss and property damage.</p>
                              
                              
                              <h4>Property Maintenance</h4>
                              <p>As a landlord, you are responsible for maintaining the property and ensuring it is safe and habitable for your tenants. This includes regular maintenance, repairs, and addressing any safety hazards.</p>
                              <h4>Rent Collection</h4>
                              <p>You are responsible for collecting rent from your tenants on time and in full. You should establish clear procedures for rent collection, including late fees and penalties for non-payment.</p>
                              <h4>Tenant Communication</h4>
                              <p>Establishing clear lines of communication with your tenants is important for resolving any issues or concerns that may arise. This includes providing contact information and responding promptly to maintenance requests and other tenant inquiries.</p>
                              <h4>Eviction Proceedings</h4>
                              <p>In the event that a tenant violates the terms of the lease or fails to pay rent, you may need to initiate eviction proceedings. It's important to follow the proper legal procedures and obtain legal assistance if necessary.</p>
                              <h4>Insurance</h4>
                              <p>As a landlord, you should have adequate insurance coverage to protect your property and assets. This may include property insurance, liability insurance, and loss of rental income insurance.</p>
                              <h4>Taxes</h4>
                              <p>Rental income is subject to taxes, and it's important to understand your tax obligations as a landlord. You may be eligible for certain deductions and credits related to your rental property.</p>
                              <p>In summary, being a landlord involves understanding legal requirements, drafting rental agreements, screening tenants, maintaining the property, collecting rent, communicating with tenants, initiating eviction proceedings if necessary, obtaining insurance, and understanding tax obligations. By keeping these factors in mind, you can ensure a successful and profitable rental experience.</p>

                            </div>
                            <div class="tab-pane text-start" role="tabpanel" id="tab-5">
                            </div>
                            <div class="tab-pane text-start" role="tabpanel" id="tab-6">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>

</template>

<script lang="js">

  export default  {
    name: 'mortgage',
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .mortgage {

  }
  p {
    font-size: 12pt;
    font-family: Average, serif;
    color: rgb(0,0,0);
  }
</style>
