import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
// //import 'bootstrap'; 
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap-vue/dist/bootstrap-vue.css';
// //import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// import VueCookies from 'vue-cookies';
// import Vue from 'vue';
// import App from './App.vue';
// import { createPinia, PiniaVuePlugin } from 'pinia';
// import router from './router';
// //import store from './store';


// // import "./assets/css/tailwind.css"

// Vue.config.productionTip = false

// // Install BootstrapVue
// //Vue.use(BootstrapVue);
// // Optionally install the BootstrapVue icon components plugin
// //Vue.use(IconsPlugin);
// // Install VeeValidate
// //Vue.use(VeeValidate, {classes: true, classNames: {invalid: 'is-invalid'}});
// Vue.use(PiniaVuePlugin);
// Vue.use(VueCookies, { expires: '30d'});

// const pinia = createPinia()

// new Vue({
//   router,
// //  store,
//   render: h => h(App),
//   pinia
// }).$mount('#app')

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

createApp(App).use(router).mount('#app')
