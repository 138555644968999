<template>

  <section class="home">

    <div class="article-list">
        <div class="container" id="main-container"><img class="img-fluid shadow" id="lookout-header" src="img/lookout1.jpg">
            <div class="row justify-content-center articles">
                <div class="col-sm-6 col-md-6 col-lg-3 d-flex flex-column item"><a href="#"><img class="img-fluid border rounded-0 shadow" src="img/UW-quad.jpg"></a>
                    <h3 class="category" style="margin-top: 25px;">Education</h3>
                    <p class="text-start flex-grow-1 description">College is one of the most important decisions you make in your life. &nbsp;It can open the door to opportunities requiring higher education but it is a major expense and may not be the best choice for everyone.</p><a class="action"
                        href="#/education"><i class="fa fa-arrow-circle-right"></i></a>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 d-flex flex-column item"><a href="#"><img class="img-fluid border rounded-0 shadow" src="img/finance.jpg"></a>
                    <h3 class="category">Finances</h3>
                    <p class="text-start flex-grow-1 description">Financial security is obtained through a good understanding of your finances, developing a plan to achieve your financial objectives, then monitoring your progress and modifying the plan as needed.</p><a class="action" href="#/finances"><i class="fa fa-arrow-circle-right"></i></a>
                </div>
                <div
                    class="col-sm-6 col-md-6 col-lg-3 d-flex flex-column item"><a href="#"><img class="img-fluid border rounded-0 shadow" src="img/house-sold.jpg"></a>
                    <h3 class="category">Mortgage</h3>
                    <p class="text-start flex-grow-1 description">Purchasing a home is probably the biggest decision you will make in your life. &nbsp;A mortgage can have a huge impact on our finances, but a house has the potential to be one of your greatest financial assets.</p><a class="action"
                        href="#/mortgage"><i class="fa fa-arrow-circle-right"></i></a>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 d-flex flex-column item"><a href="#"><img class="img-fluid border rounded-0 shadow" src="img/retired-couple.jpg"></a>
                    <h3 class="category">Retirement</h3>
                    <p class="text-start flex-grow-1 description">With fewer companies offering pensions and the future of Social Security anything but sure, sound financial planning is essential to ensuring a comfortable retirement for you and your loved ones.</p><a class="action" href="#/retirement"><i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
        </div>
    </div>

  </section>

</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>
